import React, { useState,useEffect } from "react";
import { useParams,useNavigate,Link } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import DaumPostCode from "../../../components/popup/DaumPostCode";
import DetailPhotos from '../../components/include/DetailPhotos';
import AdminShowBigSizePhoto from '../../components/include/AdminShowBigSizePhoto';

import DatePicker from "react-datepicker";
import { ko } from 'date-fns/esm/locale';
import "react-datepicker/dist/react-datepicker.css";

import {isNull,remainDays,addThousandPoint,removeThousandPoint,ChangeSearchingDateFormat,isObjEmpty,AddOptionToString,ExceptOptionToString} from "../../../components/common/Common";

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';

//둥지매물의 정보 표시
function DoongjiDetail({setMenuItem}) {
  const {rp_idx} = useParams();
  const navigate = useNavigate();

  //작성자
  //const mem_idx = Number(localStorage.getItem("adminIdx"));

  //날짜들
  const [varEnterDate,setEnterDate] = useState(null);
  const [varUsedAppDate,setUsedAppDate] = useState(null);

  //매물정보 저장 객체
  const newData = {rp_idx:Number(rp_idx),rp_mode:1,rp_rush_sale:0,rp_title:"",rp_serious:0,rp_terms:30,rp_zipcode:"",rp_location:"",
          rp_address1:"",rp_address2:"",rp_regcode:"",rp_dongname:"",rp_roadname:"",rp_name:"",rp_address_hidden:1,rp_sector:0,
          rp_bd_num:"",rp_rm_num:"",rp_purpose:0,rp_type:0,rp_deposit:0,rp_monthly:0,rp_admin_expense:0,rp_right_money:0,
          rp_include:"",rp_mortgage:0,rp_loan:0,rp_deposit_now:0,rp_monthly_now:0,rp_sale_area:0,rp_private_area:0,
          rp_use_alone:0,rp_electronic:0,rp_floor_height:0,rp_bd_location:0,rp_rooms:0,rp_baths:0,rp_bath_side:0,rp_youtube:"",
          rp_structure:0,rp_cool_heat:0,rp_exist_floor_type:0,rp_exist_floor:0,rp_total_floor:0,rp_can_parking:0,
          rp_exist_parking:0,rp_total_parking:0,rp_terrace:0,rp_direction:0,rp_elevator:0,rp_can_enter:0,rp_can_enter_date:null,
          rp_can_enter_state:0,rp_used_app_date:null,rp_can_pets:0,rp_subway_line:"",rp_subway_station:0,rp_subway_more:0,
          rp_bus:"",rp_bus_more:0,rp_market_more:0,rp_mart_more:0,rp_school:"",rp_school_more:0,rp_cctv:0,rp_translate:0,
          rp_languages:"",rp_options:"",rp_grants:0,rp_information:"",rp_hidden_phone:0,rp_register_type:0,rp_items:0,
          rp_manage_agree:0,rp_supplyinfo_agree:0,rp_mem_idx:0,rp_mcm_idx:0,rp_enter_date:null,rp_theme:"",
          rp_purpose_region:0,rp_bd_structure:0,rp_purpose_land:0,rp_rdate:null,rp_edate:null,rp_status:1,rp_show:1,rp_hidden_address:0
        };
  const [varProductDatas,setProductDatas] = useState({});
  const [varProductPhotos,setProductPhotos] = useState([]); //이미 저장된 매물사진 목록
  const [varTempProductPhotos,setTempProductPhotos] = useState([]); //신규 매물 사진 목록 - 선택된 사진 저장 배열
  const [varTempProductPhotoFiles,setTempProductPhotoFiles] = useState([]); //신규 매물 사진 정보 목록 - 선택된 사진 정보 저장 배열
  const [varEmptyProductPhotos,setEmptyProductPhotos] = useState([]); //신규 매물 사진 목록 - 비어 있는 사진 저장 배열

  const [varSubwayLines,setSubwayLines] = useState([]);
  const [varSubwayStations,setSubwayStations] = useState([]);
  const [varSelectedStations,setSelectedStations] = useState([]);

  const [varBuildingPurpose,setBuildingPurpose] = useState([]);
  const [varIncludeOptions,setIncludeOptions] = useState([]);
  const [varPurposeRegions,setPurposeRegions] = useState([]); //용도지역
  const [varPurposeLands,setPurposeLands] = useState([]); //지목
  const [varBuildingStructure,setBuildingStructure] = useState([]); //건축구조

  //매물종류목록
  const [varSeriousList,setSeriousList] = useState([]);
  //거래유형목록
  const [varTypesList,setTypesList] = useState([]);
  //내부구조
  const [varStructureList,setStructureList] = useState([]);
  //냉난방
  const [varCoolHeatList,setCoolHeatList] = useState([]);
  //방향-현관기준
  const [varDirectionList,setDirectionList] = useState([]);
  //건물위치
  const [varLocationList,setLocationList] = useState([]);
  //업종
  const [varSecterList,setSecterList] = useState([]);
  //종목
  const [varItemsList,setItemsList] = useState([]);
  //테마
  const [varThemeList,setThemeList] = useState([]);
  
  //둥지매물정보 읽어오기
  const getDoongjiInformation = async () => {
    const sendData = {rp_idx:rp_idx};
    await axios.post(`${CONFIG.SERVER_HOST}/admin_get_doongji_information`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        let photoMax = 15;
        if(datas.results?.length === 1) {
          let dataInfo = datas.results[0];
          if(dataInfo.rp_include === null) dataInfo.rp_include = "";

          //남은일자
          let remaindays = remainDays(dataInfo.doongji_expdate); //남은 기간 계산
          if(remaindays > 0) dataInfo.rp_terms = remaindays;
          //매물정보
          setProductDatas(dataInfo);

          //날짜 설정
          if(dataInfo.rp_can_enter_date !== null)
            setEnterDate(new Date(dataInfo.rp_can_enter_date));
          if(dataInfo.rp_used_app_date !== null)
            setUsedAppDate(new Date(dataInfo.rp_used_app_date));

          //매물사진정보
          setProductPhotos(datas.photos);
          photoMax = photoMax - datas.photos?.length;
        }
        //매물사진 15개까지 등록가능 --> 빈 공간 저장배열 만들기
        const photoData = {file_idx:0,file_name:"",file_preview:""};
        let photoArray = [];
        for(var i=0; i<photoMax; i++) {
          photoArray.push(photoData);
        }
        setEmptyProductPhotos(photoArray);
        //옵션전체목록정보
        /* setFilterOptions(datas.filters); */
        //지하철노선
        setSubwayLines(datas.subwaylines);
        //지하철역
        setSubwayStations(datas.subwaystations);
        //건축물용도
        setBuildingPurpose(datas.purposes);
        //옵션
        setIncludeOptions(datas.options);
        //용도지역
        setPurposeRegions(datas.purposeregions);
        //지목
        setPurposeLands(datas.purposelands);
        //건축구조
        setBuildingStructure(datas.buildstructure);
        //종목
        setItemsList(datas.bizitems);
        //건물위치
        setLocationList(datas.bdlocations);

        //매물종류
        const seriouslist = datas.filters.filter(element=>element.vsm_idx===1);
        console.log(seriouslist);
        setSeriousList(seriouslist);
        //거래유형
        const typeslist = datas.filters.filter(element=>element.vsm_idx===2);
        setTypesList(typeslist);
        //내부구조
        const structurelist = datas.filters.filter(element=>element.vsm_idx===10);
        setStructureList(structurelist);
        //냉난방
        const collheatlist = datas.filters.filter(element=>element.vsm_idx===14);
        setCoolHeatList(collheatlist);
        //방향-현관기준
        const directionlist = datas.filters.filter(element=>element.vsm_idx===15);
        setDirectionList(directionlist);
        //업종
        const secterlist = datas.filters.filter(element=>element.vsm_idx===11);
        setSecterList(secterlist);
        //테마
        const themelist = datas.filters.filter(element=>element.vsm_idx===12);
        setThemeList(themelist);
      } else {
        alert(res.data.message);
      }
    });
  }

  //페이지 정보 표시 - 최초
  useEffect(() => {
    if(Number(rp_idx) === 0) {
      setProductDatas(newData);
    }
    setMenuItem('DoongjiList');
    getDoongjiInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //지하철역 목록 불러오기
  const getSubwayStation = async (e) => {
    const { value } = e.currentTarget;
    setProductDatas({...varProductDatas,rp_subway_line:value,rp_subway_station:0});
    const newStationList = varSubwayStations.filter(element=>element.subway_line===value);
    setSelectedStations(newStationList);
  }

  useEffect(() => {
    const newStationList = varSubwayStations.filter(element=>element.subway_line===varProductDatas.rp_subway_line);
    setSelectedStations(newStationList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varProductDatas.rp_subway_line]);

  //둥지매물정보 입력
  const onChangeInputHandler = (e) => {
    const { name, value } = e.currentTarget;
    if(name==="rp_mode" || name === "rp_serious" || name === "rp_terms") { //매물종류 = 1:아파트
      console.log(value);
      setProductDatas({...varProductDatas,[name]:Number(value)});
    } else if(name === "rp_deposit" || name === "rp_monthly" || name === "rp_admin_expense" || name === "rp_right_money" ||
                            name === "rp_mortgage" || name === "rp_deposit_now" || name === "rp_monthly_now") {
      let strValue = removeThousandPoint(value);
      setProductDatas({...varProductDatas,[name]:strValue});
    } else if(name==="rp_address_hidden" || name==="rp_hidden_address"){
      const imode = Number(e.currentTarget.checked);
      setProductDatas({...varProductDatas,[name]:imode});
    } else if(name === "rp_status" || name === "rp_show") {
      setProductDatas({...varProductDatas,[name]:Number(value)});
    } else if(name === "searchMember") {
      setSearchName(value);
    } else {
      setProductDatas({...varProductDatas,[name]:value});
    }
  };

  //둥지매물정보 옵션선택
  const onChangeCheckBoxHandler = (e) => {
    const { name, value } = e.currentTarget;
    const bmode = e.currentTarget.checked;
    if(bmode) {
      if(!varProductDatas.rp_include.includes(value)) {
        const newString = AddOptionToString(value,varProductDatas.rp_include,',');
        setProductDatas({...varProductDatas,[name]:newString});
      }
    } else {
      if(varProductDatas.rp_include.includes(value)) {
        const newString = ExceptOptionToString(value,varProductDatas.rp_include,',');
        setProductDatas({...varProductDatas,[name]:newString});
      }
    }
  };

  //둥지 라디오버튼 변경
  const onChangeRadioHandler = (e,ino) => {
    const { name } = e.currentTarget;
    console.log(name);
    if(name === "rp_exist_floor_type") {
      const floorBox = document.getElementById("rp_exist_floor");
      if(ino === 0) {
        if(floorBox.classList.contains('disable')) floorBox.classList.remove('disable');
      } else {
        if(!floorBox.classList.contains('disable')) floorBox.classList.add('disable');
      }
    } else if(name === "rp_can_enter") {
      const enterDateBox = document.getElementById("enter_date");
      const enterStateBox = document.getElementById("enter_state");
      if(ino === 1) {
        if(enterDateBox.classList.contains('disable')) enterDateBox.classList.remove('disable');
        if(enterStateBox.classList.contains('disable')) enterStateBox.classList.remove('disable');
      } else {
        if(!enterDateBox.classList.contains('disable')) enterDateBox.classList.add('disable');
        if(!enterStateBox.classList.contains('disable')) enterStateBox.classList.add('disable');
      }
    } else if(name === "rp_translate") {
      const languageBox = document.querySelector(".one-line");
      console.log(languageBox);
      if(ino === 1) {
        if(languageBox.classList.contains('disable')) languageBox.classList.remove('disable');
      } else {
        if(!languageBox.classList.contains('disable')) languageBox.classList.add('disable');
      }
    } else if(name === "rp_bath_side") {
      let ichecked = Number(e.currentTarget.checked);
      let icheckedother = 0;
      if(ino===1) {
        if(document.getElementById('bath2').checked) icheckedother = 2;
      } else {
        if(ichecked) ichecked = 2;
        if(document.getElementById('bath1').checked) icheckedother = 1;
      }
      ino = ichecked + icheckedother;
    }
    setProductDatas({...varProductDatas,[name]:ino});
  }

  //우편번호/주소찾기
  const [varOpenZipcode, setOpenZipcode] = useState(false);

  //우편번호/주소 검색 팝업창 열기/닫기
  const onOpenZipcodeHandler = (e, bts) => {
    e.preventDefault();
    setOpenZipcode(bts);
  };
  //우편번호 선택으로 우편번호/주소와 같이 반환
  const ChangeAddress = (zipcode,address1,regcode,dongName,roadName,locString) => {
    console.log(locString);
    setProductDatas((prevState)=>{return{...prevState, rp_zipcode: zipcode, rp_address1: address1, rp_roadname:roadName, rp_regcode:regcode, rp_dongname:dongName,rp_location:locString}});
  };

  //매물사진 확대하기
  const [varOpenPhoto,setOpenPhoto] = useState(false);
  const [varPhotoImage,setPhotoImage] = useState(null);
  const onShowBigSizePhotoHandler = (e,files) => {
    e.preventDefault();
    setPhotoImage(files);
    setOpenPhoto(true);
  }
  const onOpenPhotoHandler = (e,bmode) => {
    setOpenPhoto(bmode);
  }

  //날짜
  const onChangeDateHandler = (ino,date) => {
    if(date === "" ) date = null;
    let sname = "";
    if(ino === 1) {
      setEnterDate(date);
      sname = 'rp_can_enter_date';
    } else {
      setUsedAppDate(date);
      sname = 'rp_used_app_date';
    }
    setProductDatas({...varProductDatas,[sname]:ChangeSearchingDateFormat(date)})
  }

  //선택한 옵션을 문자열로 조정한후에 변수에 저장
  const onSelectOptionHandler = (e) => {
    const { name,value } = e.currentTarget;
    const bmode = e.currentTarget.checked;
    let newString = "";
    if(bmode) { //rp_sector. rp_items, rp_theme
      if(isNull(varProductDatas[name]) || !varProductDatas[name].includes(value)) {
        newString = AddOptionToString(value,varProductDatas[name],',');
        setProductDatas({...varProductDatas,[name]:newString});
      }
    } else {
      if(varProductDatas[name].includes(value)) {
        newString = ExceptOptionToString(value,varProductDatas[name],',');
        setProductDatas({...varProductDatas,[name]:newString});
      }
    }
  }

  //둥지매물정보 저장--------------------------------------------------------
  const onUpdateProductHandler = async (e) => {
    e.preventDefault();

    if (varProductDatas.rp_title === '') {
      alert('둥지매물명을 입력해 주세요'); return false;
    } else if(varProductDatas.rp_mode === 0) {
      alert('등록유형을 먼저 선택하십시오'); return false;
    } else if(varProductDatas.rp_serious === 0) {
      alert('매물종류을 먼저 선택하십시오'); return false;
    } else if (varProductDatas.rp_mem_idx === 0) {
      alert('매물등록자를 검색하여 먼저 선택해 주세요'); return false;
    } else if (varProductDatas.rp_type === 0) {
      alert('거래유형을 먼저 선택해 주세요'); return false;
    } else if (varProductDatas.rp_hidden_address===0 && varProductDatas.rp_zipcode === "") {
      alert('주소를 먼저 선택해 주세요'); return false;
    } else if(varProductDatas.rp_translate === 1 && varProductDatas.rp_languages === "") {
      alert('통역 가능한 언어를 선택해 주세요'); return false;
    }

    if(!window.confirm('둥지매물의 정보를 저장합니까?')) return false;

    //서버로 업로드할 매물사진을 배열로 저장
    let PhotoForm = new FormData();
    for(var i=0; i < varTempProductPhotoFiles?.length; i++) {
      let formData = varTempProductPhotoFiles[i];
      PhotoForm.append('doongji',formData.get('doongji'));
    }
    console.log('추가된 매물 사진 서버로 업로드 등록',varTempProductPhotos?.length);
    //추가된 매물 사진 배열을 서버로 업로드 등록
    if(varTempProductPhotos?.length > 0) {
      await axios.post(`${CONFIG.SERVER_HOST}/doongjiArray`, PhotoForm).then((res) => {
        console.log(res.data);
        if(res.data.retvalue === 1) {
          const fileNames = res.data.fileNames; //배열
          console.log(fileNames);
          //매물사진 DB 등록
          RegisterDoongjiProduct(fileNames);
        }
      });
    } else {  //등록할 매물사진이 없는 경우
      RegisterDoongjiProduct("");
    }
  }
  //둥지매물정보 저장 - 데이타베이스 저장
  const RegisterDoongjiProduct = async (fileNames) => {
    //데이타베이스에 저장
    const sendData = {infos:varProductDatas};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/admin_update_doongji_information`, sendData).then((res) => {
      console.log(res.data);
      if (res.data.retvalue === 1) {
        let rp_idx = varProductDatas.rp_idx;
        if(rp_idx === 0) {
          rp_idx = res.data.rp_idx;
          setProductDatas({...varProductDatas,rp_idx:rp_idx});
        } 

        //매물사진 DB 등록
        if(fileNames?.length > 0) {
          const sendData = {rp_idx:rp_idx,fileNames:fileNames};
          console.log('sendData',sendData);
          axios.post(`${CONFIG.SERVER_HOST}/_register_doongji_photos`, sendData).then((res) => {
            console.log(res.data);
            if(res.data.retvalue === 1) {
              navigate("/Admin/ProductList/DoongjiList");
            } else {
              alert(res.data.message);
            }
          });
        } else {
          navigate("/Admin/ProductList/DoongjiList");
        }
      } else {
        alert(res.data.message);
      }
    });
  }
  //통역언어
  const onChangeLanguageHandler = (e) => {
    const { value } = e.currentTarget;
    const bmode = e.currentTarget.checked;
    console.log(value,varProductDatas.rp_languages.includes(value));
    let newString = "";
    if(bmode) {
      if(varProductDatas.rp_languages==="" || !varProductDatas.rp_languages.includes(value)) {
        newString = AddOptionToString(value,varProductDatas.rp_languages,',');
        console.log(newString);
        setProductDatas({...varProductDatas,rp_languages:newString});
      }
    } else {
      if(varProductDatas.rp_languages!=="" || varProductDatas.rp_languages.includes(value)) {
        newString = ExceptOptionToString(value,varProductDatas.rp_languages,',');
        setProductDatas({...varProductDatas,rp_languages:newString});
      }
    }
  }

  //회원페이지로
  const onGotoMemberHandler = () => {
    const imode = varProductDatas.mem_mode + 1
    navigate(`/Admin/Member/MemberDetail/${imode}/${varProductDatas.mem_idx}`);
  }

  //매물등록자 검색
  const [varSearchName,setSearchName] = useState('');
  const [varWriterList,setWriterList] = useState([]);

  //매물등록자 검색
  const onSearchMemberHandler = async(e) => {
    if(varSearchName === "") {
      alert('검색할 등록자의 이름을 먼저 입력하세요.'); return false;
    }
    const sendData = {searchName:varSearchName};
    await axios.post(`${CONFIG.SERVER_HOST}/admin_get_writer_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        const memList = datas.members;
        if(memList.length > 0) setWriterList(memList);
        else setWriterList([]);
      } else {
        setWriterList([]);
      }
    });
  }

  //검색된 등록자 선택
  const onSelectedMemberHandler = (e) => {
    const {name,value} = e.currentTarget;
    setProductDatas({...varProductDatas,[name]:value});
  }

  return isObjEmpty(varProductDatas)?null:(
    <div id="contents" className="ad_contents">
      <div className="tit">
        <div className="title">둥지 매물 정보</div>
        <div className="path">
          <img src={iconhome} alt=""/>
          HOME<img src={arrImage} alt=""/>둥지/장터관리<img src={arrImage} alt=""/>이용 관리<img src={arrImage} alt=""/>둥지 매물 정보
        </div>
      </div>
      <div className="content">
        <div className="main-box">
          <table className="write">
          <colgroup>
              <col width="15%"></col>
              <col width="30%"></col>
              <col width="15%"></col>
              <col width="40%"></col>
            </colgroup>
            <thead></thead>
            <tbody>
              <tr>
                <th>매물명</th>
                <td><input type="text" className="email-box" name="rp_title" value={varProductDatas.rp_title||""} onChange={(e)=>onChangeInputHandler(e)}/></td>
                <th>매물코드</th>
                <td>{Number(rp_idx)===0?"":varProductDatas.rp_code||""}</td>
              </tr>
              <tr>
                <th>등록유형</th>
                <td>
                  <select name="rp_mode" className="bank-box" value={varProductDatas.rp_mode||0} onChange={(e)=>onChangeInputHandler(e)}>
                    <option value={0}>등록유형선택</option>
                    <option value={1}>일반매물</option>
                    <option value={2}>중개매물</option>
                  </select>
                </td>
                <th>일반/급매물</th>
                <td>
                  <select name="rp_rush_sale" className="bank-box" value={varProductDatas.rp_rush_sale||0} onChange={(e)=>onChangeInputHandler(e)}>
                    <option value={0}>일반</option>
                    <option value={1}>급매</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>매물종류</th>
                <td>
                  <select name="rp_serious" className="bank-box" value={varProductDatas.rp_serious||0} onChange={(e)=>onChangeInputHandler(e)}>
                    <option key={"serious"} value={0}>매물종류선택</option>
                  {varSeriousList.map((serious,i)=>(
                    <option key={"serious_"+i} value={serious.vfo_idx}>{serious.vfo_name}</option>
                  ))}
                  </select>
                </td>
                <th>게재기간</th>
                <td>
                  <select name="rp_terms" className="bank-box" value={varProductDatas.rp_terms||0} onChange={(e)=>onChangeInputHandler(e)}>
                    <option>{Number(rp_idx)===0?"기간선택":"기간변경"}</option>
                    {((varProductDatas.rp_terms!==0)&&(varProductDatas.rp_terms!==15)&&(varProductDatas.rp_terms!==30)&&(varProductDatas.rp_terms!==45)&&(varProductDatas.rp_terms!==60)&&(varProductDatas.rp_terms!==90))?<option value={varProductDatas.rp_terms}>{varProductDatas.rp_terms}일</option>:null}
                    <option value={15}>15일</option>
                    <option value={30}>30일</option>
                    <option value={45}>45일</option>
                    <option value={60}>60일</option>
                    <option value={90}>90일</option>
                  </select>&nbsp;&nbsp;&nbsp;
                  {varProductDatas.rp_idx === 0? null:
                    (varProductDatas.rp_edate===null || varProductDatas.rp_edate==="")?"":`(게시마감일자 : ${varProductDatas.rp_edate.replace(/-/g,'.')})`
                  }
                </td>
              </tr>
              <tr>
                <th>동영상 URL</th>
                <td><input type="text" className="email-box" name="rp_youtube" value={varProductDatas.rp_youtube||""} onChange={(e)=>onChangeInputHandler(e)}/></td>
                <th>등록자</th>
                <td style={{cursor:"pointer"}}>
                  {Number(rp_idx)===0?
                    <>
                      <input type="text" name="searchMember" style={{width:"120px"}} value={varSearchName} onChange={(e)=>onChangeInputHandler(e)} />
                      <button style={{width:"110px"}} className="btn" onClick={e=>onSearchMemberHandler(e)}>회원검색</button>
                      <select name="rp_mem_idx" style={{width:"150px"}} value={varProductDatas.rp_mem_idx} onChange={e=>onSelectedMemberHandler(e)}>
                        <option value="">등록자선택</option>
                        {varWriterList.map((writer,i)=>(
                          <option key={'writer_'+i} value={writer.mem_idx}>{writer.mem_name}</option>
                        ))}
                      </select> 
                    </>
                  :
                    <div onClick={onGotoMemberHandler}>{isNull(varProductDatas.mcm_name)?varProductDatas.mem_name:varProductDatas.mcm_name}</div>
                  }
                  
                </td>
              </tr>
              <tr className="image-area">
                <th className="image-title">매물사진</th>
                <td colSpan={3}>
                  <div className="image-block" >
                    <DetailPhotos varMode={1} photoMax={15}
                        varProductPhotos={varProductPhotos} setProductPhotos={setProductPhotos}
                        varTempProductPhotos={varTempProductPhotos} setTempProductPhotos={setTempProductPhotos}
                        varTempProductPhotoFiles={varTempProductPhotoFiles} setTempProductPhotoFiles={setTempProductPhotoFiles}
                        varEmptyProductPhotos={varEmptyProductPhotos} setEmptyProductPhotos={setEmptyProductPhotos}
                        onShowBigSizePhotoHandler={onShowBigSizePhotoHandler}/>
                  </div>
                </td>  
              </tr>
              <tr>
                <td colSpan={4} className="product_subtitle2">
                  <div className="text-title" style={{display:"flex",justifyContent:"space-between"}}>
                    <div className="text-string" style={{fontSize:"18px"}}>매물주소</div>
                    <div>
                      <input type="checkbox" className="check-box" id="rp_hidden_address" name="rp_hidden_address" checked={varProductDatas.rp_hidden_address} onChange={(e)=>onChangeInputHandler(e)}/>
                      <label htmlFor="rp_hidden_address">주소 비공개</label>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>주소</th>
                <td colSpan={3}>
                  <input type="text" name="rp_zipcode" className="zipcode-box" value={varProductDatas.rp_zipcode||""} readOnly/>
                  <button onClick={(e)=>onOpenZipcodeHandler(e,true)} style={{width:"100px"}} className="btn line">우편번호</button><br/>
                  <input type="text" name="rp_address1" className="address1-box" value={varProductDatas.rp_address1||""} readOnly/>&nbsp;&nbsp;
                  {varProductDatas.rp_serious === 9 ? null:
                    <input type="text" name="rp_address2" className="address2-box" id={"rp_address2"} value={varProductDatas.rp_address2||""} 
                          placeholder="상세 주소를 입력해주세요." onChange={(e)=>onChangeInputHandler(e)}/>
                  }
                  {varProductDatas.rp_serious <= 4 || varProductDatas.rp_serious === 9 ? null:
                    <>
                      &nbsp;&nbsp;<input type="checkbox" className="check-box" id="add_hidden0" name="rp_address_hidden" checked={varProductDatas.rp_address_hidden} onChange={(e)=>onChangeInputHandler(e)}/>
                      &nbsp;<label htmlFor="add_hidden0">비공개</label>
                    </>
                  }
                </td>
              </tr> 
            {varProductDatas.rp_serious <= 3?
              <tr>
                <th>{varProductDatas.rp_serious===3?"빌라명":"단지명"}</th>
                <td><input type="text" className="email-box" name="rp_name" value={varProductDatas.rp_name||""} onChange={(e)=>onChangeInputHandler(e)}/></td>
                <th>공개여부</th>
                <td>
                  <input type="checkbox" className="check-box" name="rp_address_hidden" id="add_hidden1" checked={varProductDatas.rp_address_hidden} onChange={(e)=>onChangeInputHandler(e)}/>
                  &nbsp;<label htmlFor="add_hidden1">비공개</label>&nbsp;&nbsp;
                  <input type="text" className="room-box" name="rp_bd_num" value={varProductDatas.rp_bd_num||""} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;동&nbsp;
                  <input type="text" className="room-box" name="rp_rm_num" value={varProductDatas.rp_rm_num||""} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;호
                </td>
              </tr> 
            :varProductDatas.rp_serious === 4?
              <tr>
                <th>공개여부</th>
                <td colSpan={3} className="flex-block">
                  <input type="checkbox" className="check-box" name="rp_address_hidden" id="add_hidden2" checked={varProductDatas.rp_address_hidden} onChange={(e)=>onChangeInputHandler(e)}/>
                  &nbsp;<label htmlFor="add_hidden2">비공개</label>&nbsp;&nbsp;
                  <input type="text" className="room-box" name="rp_bd_num" value={varProductDatas.rp_bd_num||""} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;동&nbsp;
                  <input type="text" className="room-box" name="rp_rm_num" value={varProductDatas.rp_rm_num||""} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;호
                </td>
              </tr>
            :null
            } 
              <tr>
                <th>위치(위도,경도)</th>
                <td><input type="text" name="rp_location" value={varProductDatas.rp_location||""} onChange={(e)=>onChangeInputHandler(e)}/></td>
              {varProductDatas.rp_serious === 9?null:
                <>
                  <th>건축물용도</th>
                  <td>
                    <select name="rp_purpose" className="select-box" value={varProductDatas.rp_purpose||0} onChange={(e)=>onChangeInputHandler(e)}>
                      <option key={"purpose"} value={0}>용도선택</option>
                    {varBuildingPurpose.map((purpose,i)=>(
                      <option key={"purpose_"+i} value={purpose.vpp_idx}>{purpose.vpp_name}</option>
                    ))}
                    </select>
                  </td>
                </>
              }
              </tr> 
            {varProductDatas.rp_serious === 6?  /* 상가 */
              <>
                <tr>
                  <th>업종</th>
                  <td colSpan={3}>
                    {varSecterList.map((sectors,i)=>(
                      <span className="terms_chks" key={'sectors_'+i}>
                        <input type="checkbox" name="rp_sector" id={"sectors_"+i} className="chk1"
                              value={sectors.vfo_name || ""}
                              checked={!isNull(varProductDatas.rp_sector) && varProductDatas.rp_sector.includes(sectors.vfo_name)} 
                              onChange={e=>onSelectOptionHandler(e)}/>
                        <i></i>
                        <label htmlFor={"sectors_"+i}>{sectors.vfo_name}</label>
                      </span>
                    ))}
                  </td>
                </tr>
                <tr>
                  <th>종목</th>
                  <td colSpan={3}>
                    {varItemsList.map((items,i)=>(
                      <span className="terms_chks" key={'items_'+i}>
                        <input type="checkbox" name="rp_items" id={"items_"+i} className="chk1"
                              value={items.vfi_name || ""}
                              checked={!isNull(varProductDatas.rp_items) && varProductDatas.rp_items.includes(items.vfi_name)} 
                              onChange={e=>onSelectOptionHandler(e)}/>
                        <i></i>
                        <label htmlFor={"items_"+i}>{items.vfi_name}</label>
                      </span>
                    ))}
                  </td>
                </tr>
              </>
            :varProductDatas.rp_serious === 7?  /* 사무실 */
              <tr>
                <th>테마</th>
                <td colSpan={3}>
                  {varThemeList.map((theme,i)=>(
                    <span className="terms_chks" key={'theme_'+i}>
                      <input type="checkbox" name="rp_theme" id={"theme_"+i} className="chk1"
                            value={theme.vfo_name || ""}
                            checked={!isNull(varProductDatas.rp_theme) && varProductDatas.rp_theme.includes(theme.vfo_name)} 
                            onChange={e=>onSelectOptionHandler(e)}/>
                      <i></i>
                      <label htmlFor={"theme_"+i}>{theme.vfo_name}</label>
                    </span>
                  ))}
                </td>
              </tr>    
            :varProductDatas.rp_serious === 8 || varProductDatas.rp_serious === 9?  /* 빌딩/토지 */
              <tr>
                <th>용도지역</th>
                <td>
                  <select name="rp_purpose_region" className="select-box" value={varProductDatas.rp_purpose_region||0} onChange={(e)=>onChangeInputHandler(e)}>
                    <option key={"usearea"} value={0}>용도지역선택</option>
                  {varPurposeRegions.map((usearea,i)=>(
                    <option key={"usearea_"+i} value={usearea.vua_idx}>{usearea.vua_name}</option>
                  ))}
                  </select>
                </td>
                {varProductDatas.rp_serious === 9?
                  <><th></th><td></td></>
                :
                  <>
                    <th>지목</th>
                    <td>
                      <select name="rp_purpose_land" className="select-box" value={varProductDatas.rp_purpose_land||0} onChange={(e)=>onChangeInputHandler(e)}>
                        <option key={"jimok"} value={0}>지목선택</option>
                      {varPurposeLands.map((jimok,i)=>(
                        <option key={"jimok_"+i} value={jimok.vjs_idx}>{jimok.vjs_name}</option>
                      ))}
                      </select>
                    </td>
                  </>
                }
              </tr>   
            :varProductDatas.rp_serious === 10?  /* 공장 */
              <tr>
                <th>용도지역</th>
                <td>
                  <select name="rp_purpose_region" className="select-box" value={varProductDatas.rp_purpose_region||0} onChange={(e)=>onChangeInputHandler(e)}>
                    <option key={"usearea"} value={0}>용도지역선택</option>
                  {varPurposeRegions.map((usearea,i)=>(
                    <option key={"usearea_"+i} value={usearea.vua_idx}>{usearea.vua_name}</option>
                  ))}
                  </select>
                </td>
                <th>건축구조</th>
                <td>
                  <select name="rp_purpose_land" className="select-box" value={varProductDatas.rp_purpose_land||0} onChange={(e)=>onChangeInputHandler(e)}>
                    <option key={"build"} value={0}>건축구조선택</option>
                  {varBuildingStructure.map((build,i)=>(
                    <option key={"build"+i} value={build.vst_idx}>{build.vst_name}</option>
                  ))}
                  </select>
                </td>
              </tr> 
            :null
            }
              <tr><td colSpan={4} className="product_subtitle">금액정보</td></tr>
              <tr>
                <th>거래유형</th>
                <td>
                  <select name="rp_type" className="select-box" value={varProductDatas.rp_type||0} onChange={(e)=>onChangeInputHandler(e)}>
                    <option key={"stype"} value={0}>유형선택</option>
                  {varTypesList.map((stype,i)=>(
                    <option key={"stype_"+i} value={stype.vfo_idx}>{stype.vfo_name}</option>
                  ))}
                  </select>
                </td>
                <th>매매가/보증금/전세</th>
                <td><input type="text" className="price-box" name="rp_deposit" value={addThousandPoint(varProductDatas.rp_deposit||"")} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;만원</td>
              </tr>  
              <tr>
                <th>월세/단기</th>
                <td><input type="text" className="price-box" name="rp_monthly" value={addThousandPoint(varProductDatas.rp_monthly||"")} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;만원</td>
                {varProductDatas.rp_serious === 9 ? 
                  <><th></th><td></td></>
                :
                  <>
                    <th>관리비</th>
                    <td><input type="text" className="price-box" name="rp_admin_expense" value={addThousandPoint(varProductDatas.rp_admin_expense||"")} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;만원</td>
                  </>
                }
              </tr>
            {varProductDatas.rp_serious === 9 ? null
            : (varProductDatas.rp_serious === 6 || varProductDatas.rp_serious === 7 || varProductDatas.rp_serious === 8 || varProductDatas.rp_serious === 10)?  /* 사무실/상가/빌딩 */
              <tr>
                <th>시설권리금</th>
                <td><input type="text" className="price-box" name="rp_right_money" value={addThousandPoint(varProductDatas.rp_right_money)||""} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;만원</td>
              </tr>
            :
              <tr>
                <th>포함내역</th>
                <td colSpan={3}>
                  <input type="checkbox" className="check-box" name="rp_include" id="inc1" defaultChecked={isNull(varProductDatas.rp_include)?false:varProductDatas.rp_include.includes('전기')} value="전기" onChange={(e)=>onChangeCheckBoxHandler(e)}/>
                  &nbsp;<label htmlFor="inc1">전기</label>&nbsp;&nbsp;
                  <input type="checkbox" className="check-box" name="rp_include" id="inc2" defaultChecked={isNull(varProductDatas.rp_include)?false:varProductDatas.rp_include.includes('수도')} value="수도" onChange={(e)=>onChangeCheckBoxHandler(e)}/>
                  &nbsp;<label htmlFor="inc2">수도</label>&nbsp;&nbsp;
                  <input type="checkbox" className="check-box" name="rp_include" id="inc3" defaultChecked={isNull(varProductDatas.rp_include)?false:varProductDatas.rp_include.includes('도시가스')} value="도시가스" onChange={(e)=>onChangeCheckBoxHandler(e)}/>
                  &nbsp;<label htmlFor="inc3">도시가스</label>               
                </td>
              </tr>
            }
              <tr>
                <th>융자</th>
                <td><input type="text" className="price-box" name="rp_mortgage" value={addThousandPoint(varProductDatas.rp_mortgage)} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;만원</td>
                <th>대출</th>
                <td>
                  <select name="rp_loan" className="bank-box" value={varProductDatas.rp_loan||0} onChange={(e)=>onChangeInputHandler(e)}>
                    <option value={0}>불가능</option>
                    <option value={1}>가능</option>
                  </select>
                </td>
              </tr>
            {varProductDatas.rp_serious === 9 ? null: /* 토지 */
              <tr>
                <th>현임차보증금</th>
                <td><input type="text" className="price-box" name="rp_deposit_now" value={addThousandPoint(varProductDatas.rp_deposit_now)} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;만원</td>
                <th>현임차월세</th>
                <td><input type="text" className="price-box" name="rp_monthly_now" value={addThousandPoint(varProductDatas.rp_monthly_now)} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;만원</td>
              </tr>
            }
              <tr><td colSpan={4} className="product_subtitle">매물정보</td></tr>
            {varProductDatas.rp_serious === 9 ? /* 토지 */
              <tr>
                <th>대지면적</th>
                <td>
                  <input type="text" className="number-box" name="rp_sale_area" value={varProductDatas.rp_sale_area||""} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;평
                </td>
                <th></th><td></td>
              </tr>
            :
              <>
                <tr>
                  <th>{varProductDatas.rp_serious===5||varProductDatas.rp_serious===8||varProductDatas.rp_serious===10?"면적(대지/연면적)":"면적(공급/전용)"}</th>
                  <td>
                    <input type="text" className="number2-box" name="rp_sale_area" value={varProductDatas.rp_sale_area||""} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;평&nbsp;/&nbsp;
                    <input type="text" className="number2-box" name="rp_private_area" value={varProductDatas.rp_private_area||""} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;평
                  </td>
                {varProductDatas.rp_serious===10? /* 공장 */
                  <>
                    <th>단독사용</th>
                    <td>
                      <input type="radio" name="rp_use_alone" id="alone0" value={0} defaultChecked={varProductDatas.rp_use_alone===0?true:false} onChange={(e)=>onChangeRadioHandler(e,0)}/>
                      &nbsp;<label htmlFor="alone0">불가능</label>&nbsp;
                      <input type="radio" name="rp_use_alone" id="alone1" value={1} defaultChecked={varProductDatas.rp_use_alone===1?true:false} onChange={(e)=>onChangeRadioHandler(e,1)}/>
                      &nbsp;<label htmlFor="alone1">가능</label>
                    </td>
                  </>
                :varProductDatas.rp_serious<=4?
                  <>
                    <th>내부구조</th>
                    <td>
                      <select name="rp_structure" className="select-box" value={varProductDatas.rp_structure||0} onChange={(e)=>onChangeInputHandler(e)}>
                        <option key={"struct"} value={0}>구조선택</option>
                      {varStructureList.map((struct,i)=>(
                        <option key={"struct_"+i} value={struct.vfo_idx}>{struct.vfo_name}</option>
                      ))}
                      </select>
                    </td>
                  </>
                :varProductDatas.rp_serious===8?
                  <>
                    <th>건물위치</th>
                    <td>
                      <select name="rp_bd_location" className="select-box" value={varProductDatas.rp_bd_location||0} onChange={(e)=>onChangeInputHandler(e)}>
                        <option key={"location"} value={0}>위치선택</option>
                      {varLocationList.map((location,i)=>(
                        <option key={"location_"+i} value={location.vbl_idx}>{location.vbl_name}</option>
                      ))}
                      </select>
                    </td>
                  </>
                :null
                }
                </tr>
              {varProductDatas.rp_serious!==10?null: /* 공장 */
                <tr>
                  <th>전기전력</th>
                  <td><input type="text" className="number-box" name="rp_electronic" value={varProductDatas.rp_electronic||""} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;kW</td>                
                  <th>층고</th>
                  <td><input type="text" className="number-box" name="rp_floor_height" value={varProductDatas.rp_floor_height||""} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;m</td>                 
                </tr>
              }
              {varProductDatas.rp_serious<=5?
                <tr>
                  <th>방</th>
                  <td><input type="text" className="number2-box" name="rp_rooms" value={varProductDatas.rp_rooms||""} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;개</td>                
                  <th>화장실</th>
                  <td><input type="text" className="number2-box" name="rp_baths" value={varProductDatas.rp_baths||""} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;개</td>                     
                </tr>  
              : varProductDatas.rp_serious===6||varProductDatas.rp_serious===7?
                <tr>
                  <th>룸</th>
                  <td><input type="text" className="number2-box" name="rp_rooms" value={varProductDatas.rp_rooms||""} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;개</td>                
                  <th>화장실</th>
                  <td>
                    <input type="text" className="number2-box" name="rp_baths" value={varProductDatas.rp_baths||""} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;개&nbsp;&nbsp;
                    <input type="checkbox" name="rp_bath_side" id="bath1" value={1} checked={varProductDatas.rp_bath_side===1||varProductDatas.rp_bath_side===3} onChange={(e)=>onChangeRadioHandler(e,1)}/>
                    &nbsp;<label htmlFor="bath1">내부</label>&nbsp;
                    <input type="checkbox" name="rp_bath_side" id="bath2" value={2} checked={varProductDatas.rp_bath_side===2||varProductDatas.rp_bath_side===3} onChange={(e)=>onChangeRadioHandler(e,2)}/>
                    &nbsp;<label htmlFor="bath2">외부</label>
                  </td>                     
                </tr> 
              : 
                <tr>
                  <th>화장실</th>
                  <td colSpan={3}>
                    <input type="text" className="number2-box" name="rp_baths" value={varProductDatas.rp_baths||""} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;개&nbsp;&nbsp;
                    <input type="checkbox" name="rp_bath_side" id="bath1" value={1} checked={varProductDatas.rp_bath_side===1||varProductDatas.rp_bath_side===3} onChange={(e)=>onChangeRadioHandler(e,1)}/>
                    &nbsp;<label htmlFor="bath1">내부</label>&nbsp;
                    <input type="checkbox" name="rp_bath_side" id="bath2" value={2} checked={varProductDatas.rp_bath_side===2||varProductDatas.rp_bath_side===3} onChange={(e)=>onChangeRadioHandler(e,2)}/>
                    &nbsp;<label htmlFor="bath2">외부</label>
                  </td>                     
                </tr> 
              }
                <tr>
                  <th>냉/난방</th>
                  <td>
                    <select name="rp_cool_heat" className="select-box" value={varProductDatas.rp_cool_heat||0} onChange={(e)=>onChangeInputHandler(e)}>
                      <option key={"coolheat"} value={0}>냉/난방선택</option>
                    {varCoolHeatList.map((coolheat,i)=>(
                      <option key={"coolheat_"+i} value={coolheat.vfo_idx}>{coolheat.vfo_name}</option>
                    ))}
                    </select>
                  </td>
                  <th>총층수</th>
                  <td><input type="text" className="number-box" name="rp_total_floor" value={varProductDatas.rp_total_floor} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;층</td> 
                </tr> 
                <tr>   
                  <th>해당층</th>
                  <td colSpan={3}>
                    <input type="radio" name="rp_exist_floor_type" id="floor0" value={0} checked={varProductDatas.rp_exist_floor_type===0} onChange={(e)=>onChangeRadioHandler(e,0)}/>&nbsp;
                    <input type="text" className={varProductDatas.rp_exist_floor_type===0?"number2-box":"number2-box disable"} name="rp_exist_floor" id="rp_exist_floor" value={varProductDatas.rp_exist_floor} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;층&nbsp;&nbsp;
                    <input type="radio" name="rp_exist_floor_type" id="floor1" value={1} checked={varProductDatas.rp_exist_floor_type===1} onChange={(e)=>onChangeRadioHandler(e,1)}/>
                    &nbsp;<label htmlFor="floor1">건물전체</label>&nbsp;
                    <input type="radio" name="rp_exist_floor_type" id="floor2" value={2} checked={varProductDatas.rp_exist_floor_type===2} onChange={(e)=>onChangeRadioHandler(e,2)}/>
                    &nbsp;<label htmlFor="floor2">지하</label>&nbsp;
                    <input type="radio" name="rp_exist_floor_type" id="floor3" value={3} checked={varProductDatas.rp_exist_floor_type===3} onChange={(e)=>onChangeRadioHandler(e,3)}/>
                    &nbsp;<label htmlFor="floor3">반지하</label>&nbsp;
                    <input type="radio" name="rp_exist_floor_type" id="floor4" value={4} checked={varProductDatas.rp_exist_floor_type===4} onChange={(e)=>onChangeRadioHandler(e,4)}/>
                    &nbsp;<label htmlFor="floor4">옥탑</label>&nbsp;
                  </td> 
                </tr> 
                <tr>
                  <th>주차</th>
                  <td colSpan={3}>
                    <input type="radio" name="rp_can_parking" id="parking0" value={0} checked={varProductDatas.rp_can_parking===0} onChange={(e)=>onChangeRadioHandler(e,0)}/>
                    &nbsp;<label htmlFor="parking0">불가능</label>&nbsp;
                    <input type="radio" name="rp_can_parking" id="parking1" value={1} checked={varProductDatas.rp_can_parking===1} onChange={(e)=>onChangeRadioHandler(e,1)}/>
                    &nbsp;<label htmlFor="parking1">가능</label>&nbsp;
                    &nbsp;&nbsp;세대당주차&nbsp;
                    <input type="text" className="number2-box" name="rp_exist_parking" value={varProductDatas.rp_exist_parking} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;대
                    &nbsp;/&nbsp;총주차&nbsp;
                    <input type="text" className="number2-box" name="rp_total_parking" value={varProductDatas.rp_total_parking} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;대
                  </td>
                </tr>
              {varProductDatas.rp_serious === 6 || varProductDatas.rp_serious === 7 || varProductDatas.rp_serious === 10?  /* 사무실/상가/공장 */
                <tr>
                  <th>테라스</th>
                  <td colSpan={3}>
                    <input type="radio" name="rp_terrace" id="terrace0" value={0} checked={varProductDatas.rp_terrace===0} onChange={(e)=>onChangeRadioHandler(e,0)}/>
                    &nbsp;<label htmlFor="terrace0">없음</label>&nbsp;
                    <input type="radio" name="rp_terrace" id="terrace1" value={1} checked={varProductDatas.rp_terrace===1} onChange={(e)=>onChangeRadioHandler(e,1)}/>
                    &nbsp;<label htmlFor="terrace1">있음</label>&nbsp;
                  </td>
                </tr>
              :null
              }
                <tr>
                  <th>방향(현관기준)</th>
                  <td>
                    <select name="rp_direction" className="select-box" value={varProductDatas.rp_direction||0} onChange={(e)=>onChangeInputHandler(e)}>
                      <option key={"directs"} value={0}>방향선택</option>
                    {varDirectionList.map((directs,i)=>(
                      <option key={"directs_"+i} value={directs.vfo_idx}>{directs.vfo_name}</option>
                    ))}
                    </select>
                  </td>
                  <th>엘리베이터</th>
                  <td>
                    <input type="radio" name="rp_elevator" id="elevator0" value={0} checked={varProductDatas.rp_elevator===0} onChange={(e)=>onChangeRadioHandler(e,0)}/>
                    &nbsp;<label htmlFor="elevator0">없음</label>&nbsp;
                    <input type="radio" name="rp_elevator" id="elevator1" value={1} checked={varProductDatas.rp_elevator===1} onChange={(e)=>onChangeRadioHandler(e,1)}/>
                    &nbsp;<label htmlFor="elevator1">있음</label>
                  </td>
                </tr>
              </>
            }
              <tr>
                <th>입주가능일</th>
                <td colSpan={3}>
                  <input type="radio" name="rp_can_enter" id="canenter0" value={0} checked={varProductDatas.rp_can_enter===0} onChange={(e)=>onChangeRadioHandler(e,0)}/>
                  &nbsp;<label htmlFor="canenter0">즉시입주</label>&nbsp;
                  <input type="radio" name="rp_can_enter" id="canenter1" value={1} checked={varProductDatas.rp_can_enter===1} onChange={(e)=>onChangeRadioHandler(e,1)}/>
                  &nbsp;<label htmlFor="canenter1">협의</label>&nbsp;&nbsp;&nbsp;
                  <div className="datepicker_area" style={{width:"160px"}}>
                    <DatePicker locale={ko} name="rp_can_enter_date" id="enter_date" 
                        className={varProductDatas.rp_can_enter===0?"datepicker disable":"datepicker"} 
                        dateFormat="yyyy.MM.dd" selected={varEnterDate} onChange={(date)=>onChangeDateHandler(1,date)}/>
                  </div>&nbsp;
                  <select name="rp_can_enter_state" className={varProductDatas.rp_can_enter===0?"region-box disable":"region-box"}
                                id="enter_state" value={varProductDatas.rp_can_enter_state||0} onChange={(e)=>onChangeInputHandler(e)}>
                    <option value={0}>이전</option>
                    <option value={1}>이후</option>
                  </select>
                </td>
              </tr>
            {varProductDatas.rp_serious === 9 ? null: /* 토지 */
              <tr>
                <th>건축물사용승인일</th>
                <td colSpan={3} className="datepicker_area">
                  <DatePicker locale={ko} name="rp_used_app_date" className="datepicker" dateFormat="yyyy.MM.dd" selected={varUsedAppDate} onChange={(date)=>onChangeDateHandler(2,date)}/>
                </td>
              </tr>
            }
              <tr><td colSpan={4} className="product_subtitle">상세정보</td></tr>
              <tr>
                <th>반려동물</th>
                <td>
                  <input type="radio" name="rp_can_pets" id="pets0" value={0} checked={varProductDatas.rp_can_pets===0} onChange={(e)=>onChangeRadioHandler(e,0)}/>
                  &nbsp;<label htmlFor="pets0">불가</label>&nbsp;&nbsp;
                  <input type="radio" name="rp_can_pets" id="pets1" value={1} checked={varProductDatas.rp_can_pets===1} onChange={(e)=>onChangeRadioHandler(e,1)}/>
                  &nbsp;<label htmlFor="pets1">가능</label>&nbsp;&nbsp;
                  <input type="radio" name="rp_can_pets" id="pets2" value={2} checked={varProductDatas.rp_can_pets===2} onChange={(e)=>onChangeRadioHandler(e,2)}/>
                  &nbsp;<label htmlFor="pets2">협의</label>
                </td>
                <th>지하철역</th>
                <td>
                  <select name="rp_subway_line" className="region-box" value={varProductDatas.rp_subway_line||""} onChange={(e)=>getSubwayStation(e)}>
                    <option key={"lines"} value="">노선선택</option>
                  {varSubwayLines.map((lines,i)=>(
                    <option key={"lines_"+i} value={lines.subway_line}>{lines.subway_line}</option>
                  ))}
                  </select>&nbsp;
                  <select name="rp_subway_station" className="region2-box" value={varProductDatas.rp_subway_station||0} onChange={(e)=>onChangeInputHandler(e)}>
                    <option key={"station"} value={0}>지하철역선택</option>
                  {varSelectedStations.map((station,i)=>(
                    <option key={"station_"+i} value={station.subway_idx}>{station.subway_name}</option>
                  ))}
                  </select>&nbsp;&nbsp;도보&nbsp;
                  <input type="text" className="minute-box" name="rp_subway_more" value={varProductDatas.rp_subway_more||""} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;분
                </td>
              </tr>
              <tr>
                <th>버스정류장</th>
                <td>
                  <input type="text" name="rp_bus" className="name-box" value={varProductDatas.rp_bus||""} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;&nbsp;도보&nbsp;
                  <input type="text" name="rp_bus_more" className="minute-box" value={varProductDatas.rp_bus_more||""} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;분
                </td>
                <th>대형마트</th>
                <td>도보&nbsp;<input type="text" name="rp_market_more" className="minute-box" value={varProductDatas.rp_market_more||""} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;분</td>
              </tr>
              <tr>
                <th>편의점</th>
                <td>도보&nbsp;<input type="text" name="rp_mart_more" className="minute-box" value={varProductDatas.rp_mart_more||""} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;분</td>
                <th>인근학교</th>
                <td>
                  <input type="text" name="rp_school" className="name-box" value={varProductDatas.rp_school||""} onChange={(e)=>onChangeInputHandler(e)} placeholder="학교명 입력"/>&nbsp;&nbsp;도보&nbsp;
                  <input type="text" name="rp_school_more" className="minute-box" value={varProductDatas.rp_school_more||""} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;분
                </td>
              </tr>
              <tr>
                <th>CCTV</th>
                <td>
                  <input type="radio" name="rp_cctv" id="cctv0" value={0} checked={varProductDatas.rp_cctv===0} onChange={(e)=>onChangeRadioHandler(e,0)}/>
                  &nbsp;<label htmlFor="cctv0">없음</label>&nbsp;&nbsp;
                  <input type="radio" name="rp_cctv" id="cctv1" value={1} checked={varProductDatas.rp_cctv===1} onChange={(e)=>onChangeRadioHandler(e,1)}/>
                  &nbsp;<label htmlFor="cctv1">있음</label>&nbsp;&nbsp;
                </td>
                <th>통역</th>
                <td>
                  <input type="radio" name="rp_translate" id="trans0" value={0} checked={varProductDatas.rp_translate===0} onChange={(e)=>onChangeRadioHandler(e,0)}/>
                  &nbsp;<label htmlFor="trans0">불가</label>&nbsp;&nbsp;
                  <input type="radio" name="rp_translate" id="trans1" value={1} checked={varProductDatas.rp_translate===1} onChange={(e)=>onChangeRadioHandler(e,1)}/>
                  &nbsp;<label htmlFor="trans1">가능</label>&nbsp;&nbsp;
                  <div className="one-line line-left-row">
                    <li className="terms_chks" style={{marginRight:"20px"}}>
                      <input type="checkbox" name="rp_languages1" className="chk1" id="rp_languages1" value="영어"
                            defaultChecked={varProductDatas.rp_languages.includes('영어')} onChange={e=>onChangeLanguageHandler(e)}/>
                      <i></i><label htmlFor="rp_languages1">영어</label>
                    </li>
                    <li className="terms_chks" style={{marginRight:"20px"}}>
                      <input type="checkbox" name="rp_languages2" className="chk1" id="rp_languages2" value="중국어"
                            defaultChecked={varProductDatas.rp_languages.includes('중국어')} onChange={e=>onChangeLanguageHandler(e)}/>
                      <i></i><label htmlFor="rp_languages2">중국어</label>
                    </li>
                    <li className="terms_chks">
                      <input type="checkbox" name="rp_languages3" className="chk1" id="rp_languages3" value="일본어"
                            defaultChecked={varProductDatas.rp_languages.includes('일본어')} onChange={e=>onChangeLanguageHandler(e)}/>
                      <i></i><label htmlFor="rp_languages3">일본어</label>
                    </li>
                  </div>
                </td>
              </tr>
            {varProductDatas.rp_serious === 9? null:
              <tr><td colSpan={4} className="product_subtitle">옵션</td></tr>
            }
            {varProductDatas.rp_serious === 9? null   /* 토지 */
            : varProductDatas.rp_serious === 6 || varProductDatas.rp_serious === 7 || varProductDatas.rp_serious === 10?  /* 사무실/상가/공장 */
              <tr>
                <th>설명</th>
                <td colSpan={3}><textarea name="rp_options" value={varProductDatas.rp_options||""} onChange={(e)=>onChangeInputHandler(e)}/></td>
              </tr>
            :
              <tr>
                <td colSpan={4} className="options_list">
                  {varIncludeOptions.map((options,i)=>(
                    <span className="terms_chks" key={'options_'+i}>
                      <input type="checkbox" name="rp_options" id={"options"+i} className="chk1" 
                        defaultChecked={isNull(varProductDatas.rp_options)?false:varProductDatas.rp_options.includes(options.vpo_name)} 
                        onChange={(e)=>onSelectOptionHandler(e,options.vpo_name)}/>
                      <i></i><label htmlFor={"options"+i}>{options.vpo_name}</label>&nbsp;&nbsp;
                    </span>
                  ))}
                </td>
              </tr>
            }
              <tr><td colSpan={4} className="product_subtitle">등록옵션</td></tr>
              <tr>
                <th>부동산지원금</th>
                <td colSpan={3}><input type="text" className="price-box" name="rp_grants" value={addThousandPoint(varProductDatas.rp_grants||"")} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;만원</td>
              </tr>
              <tr>
                <th>진행상태</th>
                <td>
                  <select className="select-box" name="rp_status" value={varProductDatas.rp_status} onChange={(e)=>onChangeInputHandler(e)}>
                    <option value={-1}>진행상태선택</option>
                    <option value={0}>종료</option>
                    <option value={1}>임시저장</option>
                    <option value={2}>등록완료</option>
                  </select>
                </td>
                <th>게시상태</th>
                <td>
                  <select className="select-box" name="rp_show" value={varProductDatas.rp_show} onChange={(e)=>onChangeInputHandler(e)}>
                    <option value={-1}>게시상태선택</option>
                    <option value={0}>게시중지</option>
                    <option value={1}>거래완료</option>
                    <option value={2}>게시중</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>매물설명</th>
                <td colSpan={3}><textarea name="rp_information" value={varProductDatas.rp_information} onChange={(e)=>onChangeInputHandler(e)}/></td>
              </tr>
              {varProductDatas.mem_mode === 1 || varProductDatas.mem_mode === 2 ?  
              <tr>
              <th>전화번호 공개여부</th>
              <td>
                <input type="radio" name="rp_hidden_phone" id="hidden_phone0" value={0} checked={varProductDatas.rp_hidden_phone===0} onChange={(e)=>onChangeRadioHandler(e,0)}/>
                &nbsp;<label htmlFor="hidden_phone0">공개</label>&nbsp;&nbsp;
                <input type="radio" name="rp_hidden_phone" id="hidden_phone1" value={1} checked={varProductDatas.rp_hidden_phone===1} onChange={(e)=>onChangeRadioHandler(e,1)}/>
                &nbsp;<label htmlFor="hidden_phone1">비공개</label>&nbsp;&nbsp;
              </td>
            </tr>
              :
              <tr>
                <th>등록인유형</th>
                <td>
                <input type="radio" name="rp_register_type" id="register_type0" value={0} checked={varProductDatas.rp_register_type===0} onChange={(e)=>onChangeRadioHandler(e,0)}/>
                  &nbsp;<label htmlFor="register_type0">임차인</label>&nbsp;&nbsp;
                  <input type="radio" name="rp_register_type" id="register_type1" value={1} checked={varProductDatas.rp_register_type===1} onChange={(e)=>onChangeRadioHandler(e,1)}/>
                  &nbsp;<label htmlFor="register_type1">임대인</label>&nbsp;&nbsp;
                </td>
                <th>전화번호 공개여부</th>
                <td>
                  <input type="radio" name="rp_hidden_phone" id="hidden_phone0" value={0} checked={varProductDatas.rp_hidden_phone===0} onChange={(e)=>onChangeRadioHandler(e,0)}/>
                  &nbsp;<label htmlFor="hidden_phone0">공개</label>&nbsp;&nbsp;
                  <input type="radio" name="rp_hidden_phone" id="hidden_phone1" value={1} checked={varProductDatas.rp_hidden_phone===1} onChange={(e)=>onChangeRadioHandler(e,1)}/>
                  &nbsp;<label htmlFor="hidden_phone1">비공개</label>&nbsp;&nbsp;
                </td>
              </tr>
            }
            </tbody>
          </table>
        </div>
        <div className="btn-center">
          <button className="btn-danger" style={{width:"90px"}} onClick={(e)=>onUpdateProductHandler(e)}>{Number(rp_idx)===0?"등록":"저장"}</button>
          <Link to={"/Admin/ProductList/DoongjiList"} style={{width:"90px"}} className="btn line">목록</Link>
        </div>
      </div>
      {/* 우편번호/주소 검색*/}
      <DaumPostCode varOpenZipcode={varOpenZipcode} setOpenZipcode={setOpenZipcode} ChangeAddress={ChangeAddress} onOpenZipcodeHandler={onOpenZipcodeHandler}/>
      {/* 사진확대*/}
      <AdminShowBigSizePhoto varPhotoImage={varPhotoImage} varOpenPhoto={varOpenPhoto} onOpenPhotoHandler={onOpenPhotoHandler}/>
    </div>
  )
}

export default DoongjiDetail;