/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react"; 

import closeButton from "../../images/icon_close.png";
import bulletButton from "../../images/bullet.png";

import { SetFilterButtons, isObjEmpty } from '../common/Common';

// 필터 선택 창 - 체크박스
const FilterSelect = ({
  varFilterMode,
  varPopupFilterSelect,
  varFixedFilterList,
  setFixedFilterList,
  varFixedFilterOptionList,
  setFixedFilterOptionList,
  onPopupFilterSelectHandler
}) => {
  // 필터 변수
  const [varFilterData, setFilterData] = useState({}); 
  // 표시할 목록 가져오기
  const [varFilterOptionList, setFilterOptionList] = useState([]);
  const [varInitialOptionList, setInitialOptionList] = useState([]);

  // 페이지 정보 표시 - 최초 및 varFilterMode 또는 varPopupFilterSelect 변경 시
  useEffect(() => {
    if (varPopupFilterSelect) { // Initialize only when popup is opened
      const currentData = varFixedFilterList.filter(element => element.vsm_idx === varFilterMode);
      const currentList = varFixedFilterOptionList.filter(element => element.vsm_idx === varFilterMode);
      setFilterData(currentData[0]);
      setFilterOptionList(currentList);
      setInitialOptionList(currentList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varFilterMode, varPopupFilterSelect]);

  const onFilterSelectOptionHandler = (e, vfo_idx) => {
    const bmode = e.currentTarget.checked;

    // 매물종류의 경우
    if (varFilterData.vsm_idx === 1) {
      let ib = 0, ic = 0;
      for (let i = 0; i < varFilterOptionList.length; i++) {
        if (varFilterOptionList[i].vfo_idx <= 5) { // 주거시설
          if (varFilterOptionList[i].vfo_select === 1) ib++;
        } else {  // 비주거 시설
          if (varFilterOptionList[i].vfo_select === 1) ic++;
        }
      }
      // 매물종류가 선택된 경우
      if (ib > 0 || ic > 0) {
        if (ib > 0 && vfo_idx > 5) { // 비주거시설 선택 시 주거시설 선택 불가
          e.preventDefault();
          alert("주거시설과 비주거시설은 동시에 선택할 수 없습니다.");
          return;
        } else if (ic > 0 && vfo_idx <= 5) {  // 주거시설 선택 시 비주거시설 선택 불가
          e.preventDefault();
          alert("주거시설과 비주거시설은 동시에 선택할 수 없습니다.");
          return;
        }
      }
    }

    // 필터 배열 정리 - 페이지용
    const updatedFilterOptions = varFilterOptionList.map(option => 
      option.vfo_idx === vfo_idx ? { ...option, vfo_select: Number(bmode) } : option
    );
    setFilterOptionList(updatedFilterOptions);
  }

  // 검색 시작 
  const onSearchingFilterHandler = (e) => {
    // 필터 버튼 활성화 유무 조정 - 둥지매물필터 -> 매물유형
    let buttonList = [...varFixedFilterList];
    // 버튼 활성화 유무
    const newButtonList = SetFilterButtons(buttonList, varFilterOptionList);
    // 선택 항목 유무
    const isSelected = varFilterOptionList.some(option => option.vfo_select === 1) ? 1 : 0;
    // 배열상의 버튼 위치
    const findIndex = newButtonList.findIndex(element => element.vsm_idx === varFilterMode);
    if (findIndex !== -1) {
      // 버튼 재설정
      newButtonList[findIndex].vsm_select = isSelected;
      setFixedFilterList(newButtonList);
    }

    // 버튼 선택 실제화
    const exceptLists = varFixedFilterOptionList.filter(element => element.vsm_idx !== varFilterMode);
    const newFilterOptionList = [...exceptLists, ...varFilterOptionList];
    setFixedFilterOptionList(newFilterOptionList);

    // 팝업창 닫기
    onPopupFilterSelectHandler(e, -1);
  }

  // 창 닫기
  const onClosePopupHandler = (e) => {
    // 값 초기화 by resetting state
    setFilterOptionList(varInitialOptionList);
    onPopupFilterSelectHandler(e, false);
  }

  return isObjEmpty(varFilterData) ? null : (
    <div className="layer-pop h_auto" style={varPopupFilterSelect ? { display: "block" } : { display: "none" }}>
      <div className="pop_head">
        <div className="layer_tit">{varFilterData.vsm_name}</div>
        <div className="close_layer top_close_btn">
          <img src={closeButton} alt="" onClick={onClosePopupHandler} />
        </div>
      </div>
      <div className="layer_inner">
        {varFilterData.vsm_idx === 1 ?
          <>
            <div className="con_box">
              <div className="pop_subtit">
                <span className="bullet_wrap">
                  <img src={bulletButton} alt="" />
                </span>
                주거시설
              </div>
              <ul className="dan3">
                {varFilterOptionList.map((option, i) => (
                  i > 4 ? null :
                    <li className="terms_chk33" key={'option_' + option.vfo_idx}>
                      <input
                        type="checkbox"
                        name={"ft_" + option.vfo_idx}
                        className="chk1"
                        id={"ft_" + option.vfo_idx}
                        checked={option.vfo_select === 1}
                        onChange={(e) => onFilterSelectOptionHandler(e, option.vfo_idx)}
                      />
                      <i></i>
                      <label htmlFor={"ft_" + option.vfo_idx}>{option.vfo_name}</label>
                    </li>
                ))}
              </ul>
            </div>
            <div className="con_box">
              <div className="pop_subtit">
                <span className="bullet_wrap">
                  <img src={bulletButton} alt="" />
                </span>
                비주거시설
              </div>
              <ul className="dan3">
                {varFilterOptionList.map((option, i) => (
                  i <= 4 ? null :
                    <li className="terms_chk33" key={'option_' + option.vfo_idx}>
                      <input
                        type="checkbox"
                        name={"ft_" + option.vfo_idx}
                        className="chk1"
                        id={"ft_" + option.vfo_idx}
                        checked={option.vfo_select === 1}
                        onChange={(e) => onFilterSelectOptionHandler(e, option.vfo_idx)}
                      />
                      <i></i>
                      <label htmlFor={"ft_" + option.vfo_idx}>{option.vfo_name}</label>
                    </li>
                ))}
              </ul>
            </div>
          </>
          :
          <div className="con_box" style={{ marginTop: "10px" }}>
            <ul className="dan3">
              {varFilterOptionList.map((option, i) => (
                <li className="terms_chk33" key={'option_' + option.vfo_idx}>
                  <input
                    type="checkbox"
                    name={"ft_" + option.vfo_idx}
                    className="chk1"
                    id={"ft_" + option.vfo_idx}
                    checked={option.vfo_select === 1}
                    onChange={(e) => onFilterSelectOptionHandler(e, option.vfo_idx)}
                  />
                  <i></i>
                  <label htmlFor={"ft_" + option.vfo_idx}>{option.vfo_name}</label>
                </li>
              ))}
            </ul>
          </div>
        }
        <div className="btm_txt">* 중복 선택이 가능합니다.</div>
      </div>
      <div className="btn_bottom one_btn">
        <button className="btn_style_yes" onClick={onSearchingFilterHandler}>적용</button>
      </div>
    </div>
  );
}

export default FilterSelect;
