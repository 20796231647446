/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef, useCallback } from "react"; 
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import $ from "jquery";
import Header from "../../components/include/Header";
import Footer from "../../components/include/Footer";
import FooterCom from '../../components/include/FooterCom';
import QuickDoongjiMenu from "../../components/include/QuickDoongjiMenu";
import SearchHeader from '../../components/include/SearchHeader';
import PremiumDoongjiList from '../../components/doongji/PremiumDoongjiList';
import SearchedResult from '../../components/doongji/SearchedResult';
import SelectRegion from '../../components/include/SelectRegion';
import SelectFilters from '../../components/include/SelectFilters';
import FilterSelect from "../../components/popup/FilterSelect";
import FilterMinMax from "../../components/popup/FilterMinMax";
import FilterSubway from "../../components/popup/FilterSubway";
import SearchKeyword from "../../components/include/SearchKeyword";
import { isNull,isObjEmpty } from "../../components/common/Common";
import previewImage from "../../images/icon_pre_y.svg";
import "../../css/layout.css";
import "../../css/sub.css";
import "../../css/swiper-bundle.min.css";
import "../../css/swiper-bundle.min.css";

const DoongjiList = () => {
  const loginState = Number(localStorage.getItem("memberLoginState"));
  const mem_idx = localStorage.getItem("mem_idx");
  //검색관련 변수
  const [varSearchingString,setSearchingString] = useState('');
  //지역 필터창
  const [varFilterMode,setFilterMode] = useState(0);  //필터분류번호
  const [varSearchRegionList,setSearchRegionList] = useState([]);
  //등록유형 == 0:없음/1:일반매물/2:중개매물/3:전체
  const [varFilterOptions,setFilterOptions] = useState({});  
  //전체 필터창
  const [varFixedFilterList,setFixedFilterList] = useState([]);
  const [varFixedFilterOptionList,setFixedFilterOptionList] = useState([]);
  const [varSubwayLineList,setSubwayLineList] = useState([]);
  const [varSubwayStationList,setSubwayStationList] = useState([]);
  const [varSortingList,setSortingList] = useState([]);
  //검색된 매물목록 관련
  const [varTotalCount,setTotalCount] = useState(0);
  const [varDoongjiList,setDoongjiList] = useState([]);
  //페이지 관련
  const [varPageNum,setPageNum] = useState(1);
  const [varPageSize] = useState(10);
  const [varMorePage,setMorePage] = useState(false);
  //팝업창 열기
  const [varPopupSearchRegion,setPopupSearchRegion] = useState(false);
  const [varPopupSelectFilter,setPopupSelectFilter] = useState(false);
  const [varPopupFilterSelect,setPopupFilterSelect] = useState(false);
  const [varPopupFilterMinMax,setPopupFilterMinMax] = useState(false);
  const [varPopupFilterSubway,setPopupFilterSubway] = useState(false);
  const [varPopupSearchKeyword,setPopupSearchKeyword] = useState(false);

  // 이전 상태를 저장하기 위한 ref
  const prevStateRef = useRef({
    pageNum: null,
    searchRegionList: null,
    filterOptions: null,
    searchingString: null,
    fixedFilterList: null,
    fixedFilterOptionList: null
  });
  
  // 초기 로딩 플래그
  const initialLoadRef = useRef(true);

  // 무한 스크롤 관련 상태 및 ref
  const scrollRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  
  // 페이지 번호 증가 함수 (더보기 버튼용 및 무한 스크롤용)
  const handleLoadMore = useCallback(() => {
    if (isLoading || !varMorePage) return;
    
    console.log("더보기 실행, 페이지 증가:", varPageNum + 1);
    setIsLoading(true);
    setPageNum(prevPageNum => prevPageNum + 1);
  }, [varPageNum, isLoading, varMorePage]);
  
  // 스크롤 이벤트 핸들러
  const handleScroll = useCallback(() => {
    if (!varMorePage || isLoading) return;
    
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const clientHeight = document.documentElement.clientHeight;
    
    // 스크롤이 페이지 하단에 도달했는지 확인 (하단에서 200px 위치에 도달하면 로드)
    if (scrollHeight - scrollTop - clientHeight < 200) {
      console.log("스크롤이 하단에 도달, 더 많은 데이터 로드");
      handleLoadMore();
    }
  }, [varMorePage, isLoading, handleLoadMore]);
  
  // 스크롤 이벤트 리스너 등록 및 해제
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  //둥지옵션목록 가져오기
  const getFilterOptions = async() => {
    await axios.post(`${CONFIG.SERVER_HOST}/_get_filter_option_list`).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        //검색된 목록
        setFixedFilterList(datas.filters);
        setFixedFilterOptionList(datas.options);
        setSubwayLineList(datas.subwayline);
        setSubwayStationList(datas.subwaystation);
        //쏘팅목록
        setSortingList(datas.sortings);
      }
    });
  }

  //둥지매물 검색후 목록 가져오기
  const getDoongjiList = useCallback(async() => {
    //메모리에 저장
    if(isObjEmpty(varFixedFilterList)) return false;

    try {
      // 로딩 상태 설정
      setIsLoading(true);
      
      // 변경된 필터 옵션들을 localStorage에 저장
      localStorage.setItem('DoongjiSearchingString', varSearchingString);
      localStorage.setItem('DoongjiSearchRegionList', JSON.stringify(varSearchRegionList));
      localStorage.setItem('DoongjiFixedFilterList', JSON.stringify(varFixedFilterList));
      localStorage.setItem('DoongjiFixedFilterOptionList', JSON.stringify(varFixedFilterOptionList));
      localStorage.setItem('DoongjiFilterOptions', JSON.stringify(varFilterOptions));
      localStorage.setItem('DoongjiSortingList', JSON.stringify(varSortingList));

      // === 필터 사용 여부 체크 ===
      const regionFiltered = varSearchRegionList?.some(item => item.vsm_select === 1);
      const optionFiltered = varFixedFilterOptionList?.some(item => item.vfo_select === 1);
      const searchStringFiltered = (!isNull(varSearchingString) && varSearchingString.trim() !== "");

      let filterFlag = 0;
      if (regionFiltered || optionFiltered || searchStringFiltered) {
        filterFlag = 1;
      }

      const sendData = {
        mem_idx: mem_idx,
        pageNum: varPageNum,
        pageSize: varPageSize,
        searchString: varSearchingString,
        searchRegion: varSearchRegionList,
        filters: varFixedFilterList,
        options: varFixedFilterOptionList,
        sorting: varFilterOptions,
        filterFlag: filterFlag
      };
      console.log("API 요청 데이터:", sendData);

      const response = await axios.post(`${CONFIG.SERVER_HOST}/_get_doongji_list`, sendData);
      const datas = response.data;
      console.log("API 응답 데이터:", datas);
      
      if(datas.retvalue === 1) {
        let filteredResults = datas.results || [];
        let totalResults = datas.totalcount || 0;
        
        if(varSearchRegionList.length !== 0) {
          // 주소 비공개(rp_hidden_address===1) 필터링
          filteredResults = filteredResults.filter(item => item.rp_hidden_address !== 1);
          totalResults = filteredResults.length;
        }
        
        // 페이지 번호가 1이면 목록을 새로 설정, 아니면 기존 목록에 추가
        if(varPageNum === 1) {
          console.log("첫 페이지 로딩, 목록 초기화");
          setTotalCount(totalResults);
          setDoongjiList(filteredResults);
        } else {
          console.log("추가 페이지 로딩, 목록 추가", varPageNum);
          // 함수형 업데이트를 사용하여 이전 상태에 새 결과를 추가
          setDoongjiList(prevList => {
            // 중복 항목 제거를 위해 ID 기반으로 필터링 (ID 필드명은 실제 데이터에 맞게 조정 필요)
            const existingIds = new Set(prevList.map(item => item.rp_idx));
            const newItems = filteredResults.filter(item => !existingIds.has(item.rp_idx));
            return [...prevList, ...newItems];
          });
        }
        
        // 더 보여줄 페이지가 있는지 확인
        const hasMorePages = totalResults > (varPageNum * varPageSize);
        console.log("더 보여줄 페이지 여부:", hasMorePages, "총 결과:", totalResults, "현재 페이지:", varPageNum);
        setMorePage(hasMorePages);
      } 
      else {
        console.log("API 응답 오류");
        if(varPageNum === 1) {
          // 첫 페이지에서만 목록을 초기화
          setTotalCount(0);
          setDoongjiList([]);
        }
        setMorePage(false);
      }
    } catch (error) {
      console.error("API 호출 오류:", error);
      if(varPageNum === 1) {
        // 첫 페이지에서만 목록을 초기화
        setTotalCount(0);
        setDoongjiList([]);
      }
      setMorePage(false);
    } finally {
      // 로딩 상태 해제
      setIsLoading(false);
    }
  }, [varFixedFilterList, varSearchingString, varSearchRegionList, varFixedFilterOptionList, varFilterOptions, varSortingList, mem_idx, varPageNum, varPageSize]);

  const onShowQuickMenuHandler = (e,bmode) => {
    if(bmode) {
      $(".quick_pop").fadeIn("1500");
      $(".layer-bg").fadeIn("1500");
      $(".quick_close_btn").fadeIn("1500");
    } else {
      $(".quick_pop").fadeOut("1500");
      $(".layer-bg").fadeOut("1500");
      $(".quick_close_btn").fadeOut("1500");
    }
  }
  const onPopupWinHandler = (bmode) => {
    if(bmode) {
      $(".layer-bg").fadeIn("1500");
    } else {
      $(".layer-bg").fadeOut("1500");
    }
  }

  //지역선택 팝업창
  const onPopupRegionHandler = (e,bmode) => {
    e.preventDefault();
    if(bmode === -1) {
      onPopupWinHandler(false);
      setPopupSearchRegion(false);
      setPageNum(1);
    } else {
      onPopupWinHandler(bmode);
      setPopupSearchRegion(bmode);
    }
  }
  const onPopupFilterHandler = (e,bmode) => {
    e.preventDefault();
    if(bmode === -1) {
      onPopupWinHandler(false);
      setPopupSelectFilter(false);
      setPageNum(1);
    } else {
      onPopupWinHandler(bmode);
      setPopupSelectFilter(bmode);
    }
  }
  //필터링
  const onSelectFilterHandler = (e,vsm_idx) => {
    e.preventDefault();
    setFilterMode(vsm_idx);
    onPopupWinHandler(true);
    switch(vsm_idx) {
      case 16: 
        setPopupFilterSubway(true); 
        break;
      case 3:
      case 4:
      case 5:
        setPopupFilterMinMax(true); 
        break;
      default:
        setPopupFilterSelect(true); 
        break;
    }
  }
  const onPopupFilterSelectHandler = (e,bmode) => {
    e.preventDefault();
    if(bmode === -1) {
      onPopupWinHandler(false);
      setPopupFilterSelect(false);
      setPageNum(1);
    } else {
      onPopupWinHandler(bmode);
      setPopupFilterSelect(bmode);
    }
  }
  const onPopupFilterMinMaxHandler = (e,bmode) => {
    e.preventDefault();
    if(bmode === -1) {
      onPopupWinHandler(false);
      setPopupFilterMinMax(false);
      setPageNum(1);
    } else {
      onPopupWinHandler(bmode);
      setPopupFilterMinMax(bmode);
    }
  }
  const onPopupFilterSubwayHandler = (e,bmode) => {
    e.preventDefault();
    if(bmode === -1) {
      onPopupWinHandler(false);
      setPopupFilterSubway(false);
      setPageNum(1);
    } else {
      onPopupWinHandler(bmode);
      setPopupFilterSubway(bmode);
    }
  }
  const onPopupSearchKeywordHandler = (e,bmode) => {
    e.preventDefault();
    if(bmode === -1) {
      onPopupWinHandler(false);
      setPopupSearchKeyword(false);
      setPageNum(1);
    } else {
      setPopupSearchKeyword(bmode);
      onPopupWinHandler(bmode);
    }
  }

  //매물종류 = 중개매물/일반매물
  const FilterReloading = useCallback(() => {
    // 페이지 번호를 1로 초기화하면 재검색이 트리거됩니다.
    console.log("필터 변경으로 페이지 번호 리셋 (FilterReloading)");
    setPageNum(1);
  }, []);

  // 필터 초기화
  const onReloadHandler = async (e) => {
    if(!window.confirm('설정된 모든 필터를 초기화하시겠습니까?')) return false;
    // localStorage 초기화
    localStorage.removeItem('DoongjiSearchingString');
    localStorage.removeItem('DoongjiSearchRegionList');
    localStorage.removeItem('DoongjiFixedFilterList');
    localStorage.removeItem('DoongjiFixedFilterOptionList');
    localStorage.removeItem('DoongjiFilterOptions');
    localStorage.removeItem('DoongjiSortingList');
    // 서버에서 다시 필터 옵션을 가져와서 초기화
    await getFilterOptions();

    // 정렬, 매물유형 등 기본값 세팅
    setFilterOptions({ rp_mode: 3, filters: 2 });
    setSearchingString('');
    setSearchRegionList([]);
    setPageNum(1);
  }

  //페이지 정보 표시 - 최초
  useEffect(() => {
    $('.btm_quick_menu').show();
    $('.btm_quick_menu .q_open_btn').show();
    // 기본값 세팅
    setFilterOptions({ rp_mode:3, filters:2 });
    // 기존에 저장된 검색 세션값 불러오기
    const DoongjiSearchingString = localStorage.getItem('DoongjiSearchingString');
    if(!isNull(DoongjiSearchingString)) setSearchingString(DoongjiSearchingString);
    const DoongjiSearchRegionList = localStorage.getItem('DoongjiSearchRegionList');
    if(!isNull(DoongjiSearchRegionList)) setSearchRegionList(JSON.parse(DoongjiSearchRegionList));
    const DoongjiFixedFilterList = localStorage.getItem('DoongjiFixedFilterList');
    if(!isNull(DoongjiFixedFilterList)) setFixedFilterList(JSON.parse(DoongjiFixedFilterList));
    const DoongjiFixedFilterOptionList = localStorage.getItem('DoongjiFixedFilterOptionList');
    if(!isNull(DoongjiFixedFilterOptionList)) setFixedFilterOptionList(JSON.parse(DoongjiFixedFilterOptionList));
    const DoongjiFilterOptions = localStorage.getItem('DoongjiFilterOptions');
    if(!isNull(DoongjiFilterOptions)) setFilterOptions(JSON.parse(DoongjiFilterOptions));
    const DoongjiSortingList = localStorage.getItem('DoongjiSortingList');
    if(!isNull(DoongjiSortingList)) setSortingList(JSON.parse(DoongjiSortingList));
    if(isNull(DoongjiFixedFilterOptionList)) getFilterOptions();
    
    // 초기 로딩 후 플래그 설정
    initialLoadRef.current = false;
  }, []);

  // 상태 변경 감지 및 API 호출
  useEffect(() => {
    // 초기 로딩 시에는 실행하지 않음
    if (initialLoadRef.current) return;
    
    // 필요한 데이터가 없으면 API 호출하지 않음
    if (isObjEmpty(varFixedFilterList)) return;
    
    // 이전 상태와 현재 상태 비교
    const prevState = prevStateRef.current;
    
    // 페이지 번호가 변경된 경우 (더보기 버튼 클릭)
    if (prevState.pageNum !== varPageNum) {
      console.log("페이지 번호 변경, API 호출", varPageNum);
      getDoongjiList();
      
      // 페이지 번호만 업데이트
      prevStateRef.current = {
        ...prevState,
        pageNum: varPageNum
      };
      return;
    }
    
    // 다른 필터 조건이 변경된 경우
    const otherStateChanged = 
      JSON.stringify(prevState.searchRegionList) !== JSON.stringify(varSearchRegionList) ||
      JSON.stringify(prevState.filterOptions) !== JSON.stringify(varFilterOptions) ||
      prevState.searchingString !== varSearchingString ||
      JSON.stringify(prevState.fixedFilterList) !== JSON.stringify(varFixedFilterList) ||
      JSON.stringify(prevState.fixedFilterOptionList) !== JSON.stringify(varFixedFilterOptionList);
    
    if (otherStateChanged) {
      console.log("필터 조건 변경, API 호출");
      console.log("varSearchRegionList :", varSearchRegionList);
      
      // 필터 조건이 변경되면 페이지 번호를 1로 리셋
      if (varPageNum !== 1) {
        console.log("필터 변경으로 페이지 번호 리셋");
        setPageNum(1);
        return; // 페이지 번호가 변경되면 다음 useEffect 실행에서 API 호출
      }
      
      // 페이지 번호가 이미 1이면 바로 API 호출
      getDoongjiList();
      
      // 현재 상태를 이전 상태로 저장
      prevStateRef.current = {
        pageNum: varPageNum,
        searchRegionList: varSearchRegionList.length > 0 ? [...varSearchRegionList] : [],
        filterOptions: varFilterOptions ? {...varFilterOptions} : {},
        searchingString: varSearchingString,
        fixedFilterList: varFixedFilterList.length > 0 ? [...varFixedFilterList] : [],
        fixedFilterOptionList: varFixedFilterOptionList.length > 0 ? [...varFixedFilterOptionList] : []
      };
    }
  }, [varPageNum, varSearchRegionList, varFilterOptions, varSearchingString, varFixedFilterList, varFixedFilterOptionList, getDoongjiList]);

  return (
    <>
      <Header/>
      <div id="content">
        {/* 배경죽이는 항목 */}
        <div className="layer-bg" style={{backgroundColor:"rgb(0 0 0 / 69%)"}}></div>  
        <div className="quick_close_btn close-layer">
          <img src={previewImage} alt="" onClick={e=>onShowQuickMenuHandler(e,false)} style={{cursor:"pointer"}}/>&nbsp;&nbsp;뒤로가기
        </div>
        {loginState===0 ? null : <QuickDoongjiMenu onShowQuickMenuHandler={onShowQuickMenuHandler} />}
        <div className="main_wrap">
          {/* 검색할 지역 선택 및 검색문자 입력 */}
          <SearchHeader 
            varSearchingString={varSearchingString} 
            setSearchingString={setSearchingString} 
            onPopupSearchKeywordHandler={onPopupSearchKeywordHandler} 
            varSearchRegionList={varSearchRegionList} 
            setSearchRegionList={setSearchRegionList}
            onPopupRegionHandler={onPopupRegionHandler} 
            setPageNum={setPageNum}
          />
          <div className="main_con">
            {/* 프리미엄광고 표시부 */}
            <PremiumDoongjiList varSearchRegionList={varSearchRegionList} />
            {/* 검색결과 매물목록 표시부 */}
            <SearchedResult 
              varSortingList={varSortingList} 
              varTotalCount={varTotalCount} 
              varFilterOptions={varFilterOptions} 
              setFilterOptions={setFilterOptions}
              varDoongjiList={varDoongjiList} 
              setDoongjiList={setDoongjiList} 
              varFixedFilterList={varFixedFilterList} 
              getFilterOptions={getFilterOptions}
              onSelectFilterHandler={onSelectFilterHandler} 
              onPopupFilterHandler={onPopupFilterHandler}
              FilterReloading={FilterReloading} 
              varMorePage={varMorePage} 
              varPageNum={varPageNum} 
              setPageNum={handleLoadMore}
              onReloadHandler={onReloadHandler}
              isLoading={isLoading}
            />
            
            {/* 로딩 인디케이터 */}
            {isLoading && (
              <div className="loading-container">
                <div className="loading-spinner">
                </div>
              </div>
            )}
            
            {/* 스크롤 감지를 위한 참조 요소 */}
            <div ref={scrollRef} style={{ height: '1px' }}></div>
          </div>
          {/* 지역선택팝업창------ */}
          <SelectRegion 
            varPopupSearchRegion={varPopupSearchRegion} 
            onPopupRegionHandler={onPopupRegionHandler}
            varSearchRegionList={varSearchRegionList} 
            setSearchRegionList={setSearchRegionList} 
          />
          {/* 전체 필터창------- */}
          <SelectFilters 
            varPopupSelectFilter={varPopupSelectFilter} 
            varSubwayLineList={varSubwayLineList} 
            varSubwayStationList={varSubwayStationList}
            varFixedFilterList={varFixedFilterList} 
            setFixedFilterList={setFixedFilterList}
            varFixedFilterOptionList={varFixedFilterOptionList} 
            setFixedFilterOptionList={setFixedFilterOptionList} 
            onPopupFilterHandler={onPopupFilterHandler} 
            onSelectFilterHandler={onSelectFilterHandler}
          />
          {/* 팝업창-선택형------- */}
          <FilterSelect 
            varFilterMode={varFilterMode} 
            varPopupFilterSelect={varPopupFilterSelect}
            varFixedFilterList={varFixedFilterList} 
            setFixedFilterList={setFixedFilterList}
            varFixedFilterOptionList={varFixedFilterOptionList} 
            setFixedFilterOptionList={setFixedFilterOptionList} 
            onPopupFilterSelectHandler={onPopupFilterSelectHandler} 
          />  
          {/* 팝업창-최소/최대값지정형------- */}
          <FilterMinMax 
            varFilterMode={varFilterMode} 
            varPopupFilterMinMax={varPopupFilterMinMax}
            varFixedFilterList={varFixedFilterList} 
            setFixedFilterList={setFixedFilterList}
            onPopupFilterMinMaxHandler={onPopupFilterMinMaxHandler}
          /> 
          {/* 팝업창-지하철역 선택형------- */}
          <FilterSubway 
            varFilterMode={varFilterMode} 
            varPopupFilterSubway={varPopupFilterSubway}
            varSubwayLineList={varSubwayLineList} 
            varSubwayStationList={varSubwayStationList}
            varFixedFilterList={varFixedFilterList} 
            setFixedFilterList={setFixedFilterList}
            onPopupFilterSubwayHandler={onPopupFilterSubwayHandler}
          /> 
          {/* 키워드 검색 팝업창--------- */}  
          <SearchKeyword 
            varPopupSearchKeyword={varPopupSearchKeyword} 
            onPopupSearchKeywordHandler={onPopupSearchKeywordHandler}
            setSearchingString={setSearchingString} 
          />
        </div>
        <FooterCom/>
      </div>
      <Footer varPageMode={1} />
    </>
  );
}

export default DoongjiList;
