import React, { useState,useEffect } from "react";
import { Link,useParams,useNavigate } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../../configs/Configs';
import DaumPostCode from "../../../components/popup/DaumPostCode";
import { ChangeMobileFormat,ChangeMobileNumber,isObjEmpty,isNull } from '../../../components/common/Common';

import iconhome from '../../images/icon-home.png';
import arrImage from '../../images/arr.png';

import "../../admin_css/admin_layout.css";
import "../../admin_css/admin.css";
import '../../admin_css/board.css';

//공인 중개사 파트너 모집
function PartnerDetail({setMenuItem}) {
  const {pageMode,indexNum} = useParams();

  const navigate = useNavigate();
  const mem_idx = localStorage.getItem("mem_idx");
 
  //신규정보
  const newData = {
    rw_idx:0,
    mem_idx:mem_idx,
    rw_license:0,
    rw_zipcode:"",
    rw_address1:"",
    rw_address2:"",
    rw_roadname:"",
    rw_regcode:"",
    rw_dongname:"",
    rw_location:"",
    rw_career:0,
    rw_career_year:0,
    rw_name:"",
    rw_gender:0,
    rw_age:0,
    rw_comment:"",
    rw_phone:"",
    rw_memo:"",
    rw_rdate:null
  }

  //선택/추가할 저장 변수 
  const [varServiceDatas, setServiceDatas] = useState({});

  //정보 읽어오기
  const getServiceInformation = async () => {
    const sendData = {pageMode:4, indexNum:indexNum};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_service_information`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        setServiceDatas(datas.results);
      } else {
        setServiceDatas(newData);
      }
    });
  }

  //페이지 정보 표시 - 최초
  useEffect(() => {
    setMenuItem('DoongjiPartner');
    getServiceInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //우편번호/주소찾기-------------------------------------------------------
  const [varOpenZipcode, setOpenZipcode] = useState(false);
  //우편번호/주소 검색 팝업창 열기/닫기
  const onOpenZipcodeHandler = (e, bts) => {
    //e.preventDefault();
    setOpenZipcode(bts);
  };
  //우편번호 선택으로 우편번호/주소와 같이 반환
  const ChangeAddress = (zipcode,address1,regcode,dongName,roadName,locString) => {
    console.log(zipcode,address1,regcode,dongName,roadName,locString);
    const name1 = 'rw_zipcode';
    const name2 = 'rw_address1';
    const name3 = 'rw_regcode';
    const name4 = 'rw_dongname';
    const name5 = 'rw_roadname';
    const name6 = 'rw_location';
    setServiceDatas((prevState) => ({ ...prevState, [name1]: zipcode, [name2]: address1, [name3]: regcode, [name4]: dongName
      , [name5]: roadName, [name6]: locString }));
  };

  //정보 입력--------------------------------------------------------
  const onChangeInputHandler = (e) => {
    const { name, value } = e.currentTarget;
    if(name === "rw_phone") {
      setServiceDatas({...varServiceDatas,[name]:ChangeMobileNumber(value)});
    } else {
      setServiceDatas({...varServiceDatas,[name]:value});
    }
  };
  const onChangeLicenseHandler = (e,ino) => {
    setServiceDatas({ ...varServiceDatas, rw_license:ino });
  }
  const onChangeCareerHandler = (e,ino) => {
    const careerYearBox = document.getElementById('rw_career_year');
    if(ino === 0) {
      if(!careerYearBox.classList.contains('disable')) careerYearBox.classList.add('disable');
    } else {
      if(careerYearBox.classList.contains('disable')) careerYearBox.classList.remove('disable')
    }
    setServiceDatas({ ...varServiceDatas, rw_career:ino });
  }

  //신고물 정보 저장-------------------------------------------------------
  const onUpdateServiceHandler = async (e) => {
    console.log(varServiceDatas);
    if(isNull(varServiceDatas.rw_name)) {
      alert("신청자명을 먼저 입력하세요."); return false;    
    } else if(varServiceDatas.rw_career === 1 && (varServiceDatas.rw_career_year==="" || varServiceDatas.rw_career_year==="0")) {
      alert("부동산 경력을 먼저 입력하세요."); return false;
    // } else if(varServiceDatas.rw_zipcode==="") {
    //   alert("우편번호/주소를 먼저 선택하세요.");return false;
    /* } else if(varServiceDatas.rw_company==="") {
      alert("사무실명을 먼저 입력하세요."); return false; */
    } else if(varServiceDatas.rw_age===0 || isNull(varServiceDatas.rw_age)) {
      alert("나이를 먼저 입력하세요."); return false;
    /* } else if(varServiceDatas.rw_comment==="") {
      alert("의뢰내용을 먼저 입력하세요."); return false;
    } else if(varServiceDatas.rw_phone==="") {
      alert("담당자 연락처를 먼저 입력하세요."); return false; */
    }

    if(!window.confirm('정보를 저장합니까?')) return false;

    const sendData = {pageMode:pageMode, indexNum:indexNum, infos:varServiceDatas};
    await axios.post(`${CONFIG.SERVER_HOST}/_update_service_information`, sendData).then((res) => {
      alert(res.data.message);
      if (res.data.retvalue === 1) {
        //상태 업데이트
        if(window.updateServiceState) window.updateServiceState();
        navigate(`/Admin/ProductService/DoongjiPartner`);
      }
    });
  }

  return isObjEmpty(varServiceDatas)?null: (
    <div id="contents" className="ad_contents">
      <div className="tit">공인중개사 파트너 모집
        <div className="path">
          <img src={iconhome} alt=""/>
          HOME<img src={arrImage} alt=""/>둥지/장터관리<img src={arrImage} alt=""/>기타 서비스 관리<img src={arrImage} alt=""/>공인중개사 파트너 모집
        </div>
      </div>
      <div className="content">
        <div className="main-box">
          <table className="write">
          <colgroup>
              <col width="20%"></col>
              <col width="30%"></col>
              <col width="20%"></col>
              <col width="30%"></col>
            </colgroup>
            <thead></thead>
            <tbody>
              <tr>
                <th>신청자명 <span style={{color:'#E51D1D',fontWeight:'normal',fontSize:'12px'}}> (필수)</span></th>
                <td colSpan={3}>
                  <input type="text" className="mobile-box" name="rw_name" value={varServiceDatas.rw_name||""} onChange={(e)=>onChangeInputHandler(e)}/>
                </td>
              </tr>
              <tr>
                <th>사무실 주소</th>
                <td colSpan={3}>
                  <input type="text" className="zipcode-box" name="rw_zipcode" value={varServiceDatas.rw_zipcode||""} readOnly/>
                  <button onClick={(e)=>onOpenZipcodeHandler(e,true)} className="btn" style={{width:"120px"}}>우편번호</button><br/>
                  <input type="text" className="address1-box" name="rw_address1" value={varServiceDatas.rw_address1||""} readOnly/>&nbsp;&nbsp;
                  <input type="text" className="address2-box" name="rw_address2" id="rw_address2" value={varServiceDatas.rw_address2||""} 
                          placeholder="상세 주소를 입력해주세요." onChange={(e)=>onChangeInputHandler(e)}/>
                </td>
              </tr>
              <tr>
                <th>공인중개사자격증 <span style={{color:'#E51D1D',fontWeight:'normal',fontSize:'12px'}}> (필수)</span></th>
                <td>
                  <input type="radio" name="rw_license" id="rw_license0" value={0} onChange={(e)=>onChangeLicenseHandler(e,0)} defaultChecked={varServiceDatas.rw_license===0?true:false}/>&nbsp;
                  <label htmlFor="rw_license0">없음</label>&nbsp;&nbsp;
                  <input type="radio" name="rw_license" id="rw_license1" value={1} onChange={(e)=>onChangeLicenseHandler(e,1)} defaultChecked={varServiceDatas.rw_license===1?true:false}/>&nbsp;
                  <label htmlFor="rw_license1">있음</label>
                </td>
                <th>부동산 경력 <span style={{color:'#E51D1D',fontWeight:'normal',fontSize:'12px'}}> (필수)</span></th>
                <td>
                  <input type="radio" name="rw_career" id="rw_career0" value={0} onChange={(e)=>onChangeCareerHandler(e,0)} defaultChecked={varServiceDatas.rw_career===0?true:false}/>&nbsp;
                  <label htmlFor="rw_career0">없음</label>&nbsp;&nbsp;
                  <input type="radio" name="rw_career" id="rw_career1" value={1} onChange={(e)=>onChangeCareerHandler(e,1)} defaultChecked={varServiceDatas.rw_career===1?true:false}/>&nbsp;
                  <label htmlFor="rw_career1">있음</label>&nbsp;&nbsp;
                  <input type="text" className={varServiceDatas.rw_career===0?"room-box disable":"room-box"} name="rw_career_year" id="rw_career_year" value={varServiceDatas.rw_career_year} onChange={(e)=>onChangeInputHandler(e)}/>&nbsp;년
                </td>
              </tr>
              <tr>
                <th>성별 <span style={{color:'#E51D1D',fontWeight:'normal',fontSize:'12px'}}> (필수)</span></th>
                <td>
                  <input type="radio" name="rw_gender" id="gender0" value={0} defaultChecked={varServiceDatas.rw_gender===0?true:false} onChange={(e)=>onChangeInputHandler(e)}/>
                  <label htmlFor="gender0">남성</label>
                  <input type="radio" name="rw_gender" id="gender1" value={1} defaultChecked={varServiceDatas.rw_gender===1?true:false} onChange={(e)=>onChangeInputHandler(e)}/>
                  <label htmlFor="gender1">여성</label>
                </td>
                <th>나이 <span style={{color:'#E51D1D',fontWeight:'normal',fontSize:'12px'}}> (필수)</span></th>
                <td><input type="text" className="mobile-box" name="rw_age" value={varServiceDatas.rw_age} onChange={(e)=>onChangeInputHandler(e)}/></td>
              </tr>
              <tr>
                <th>신청내용</th>
                <td colSpan={3}>
                  <textarea name="rw_comment" value={varServiceDatas.rw_comment} onChange={(e)=>onChangeInputHandler(e)} style={{width:"100%,height:60px"}}></textarea>
                </td>
              </tr>
              <tr>
                <th>메모</th>
                <td colSpan={3}>
                  <textarea name="rw_memo" value={varServiceDatas.rw_memo} onChange={(e)=>onChangeInputHandler(e)} style={{width:"100%,height:60px"}}></textarea>
                </td>
              </tr>
              <tr>
                <th>담당자 연락처</th>
                <td>
                  <input type="text" className="mobile-box" name="rw_phone" value={ChangeMobileFormat(varServiceDatas.rw_phone)} onChange={(e)=>onChangeInputHandler(e)}/>
                </td>
                <th>진행상태</th>
                <td>
                  <select className="select-box" name="rw_status" onChange={(e)=>onChangeInputHandler(e)} value={varServiceDatas.rw_status}>
                    <option value={0}>종료</option>
                    <option value={1}>신청중</option>
                    <option value={2}>확인완료</option>
                    <option value={3}>답변완료</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="btn-center">
          <button className="btn-danger" onClick={(e)=>onUpdateServiceHandler(e)}>저장</button>
          <Link to={`/Admin/ProductService/DoongjiPartner`} style={{width:"100px"}} className="btn line">목록</Link>
        </div>
        {/* 우편번호/주소 검색*/}
        <DaumPostCode varOpenZipcode={varOpenZipcode} setOpenZipcode={setOpenZipcode} ChangeAddress={ChangeAddress} onOpenZipcodeHandler={onOpenZipcodeHandler}/>
      </div>
    </div>
  )
}

export default PartnerDetail;