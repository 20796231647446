/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';

import axios from 'axios';
import CONFIG from '../../configs/Configs';
import $ from "jquery";

import { TimeFormat, sendMessageToMobile, isObjEmpty, ChangeMobileFormat, GetPhotoImage, randomStringOnlyNumber, checkEmailString, OnlyNumerics, convertHeicToJpeg } from '../../components/common/Common';
import HeadTitle from '../../components/include/HeadTitle';
import ExpireMember from '../../components/popup/ExpireMember';
import DaumPostCode from "../../components/popup/DaumPostCode";

import arrowRightIcon from "../../images/arrow_right_on.png";

import companyPhoto from "../../images/sub/@thumnail03.png";
import taxPhoto from "../../images/sub/@thumnail03.png";
import licensePhoto from "../../images/sub/@thumnail03.png";

//mem_mode=1:부동산파트너/=2:사업자 파트너
const MyPartnerInfo = () => {
  const mem_idx = Number(localStorage.getItem("mem_idx"));
  const mem_mode = Number(localStorage.getItem("mem_mode"));
  localStorage.setItem("foundIdx", mem_idx); // 패스워드변경시 필요
  const navigate = useNavigate();

  //파일관련
  const fileRef1 = useRef(null);
  const fileRef2 = useRef(null);
  const fileRef3 = useRef(null);

  //전화번호
  const hpRef1 = useRef(null);
  const hpRef2 = useRef(null);
  const hpRef3 = useRef(null);

  const phRef1 = useRef(null);
  const phRef2 = useRef(null);
  const phRef3 = useRef(null);

  const [varEditPhoto, setEditPhoto] = useState(false);
  const [varEditTax, setEditTax] = useState(false);
  const [varEditBroker, setEditBroker] = useState(false);

  //회원정보
  const [varMember, setMember] = useState({});
  //인증정보
  const [varCheckMember, setCheckMember] = useState({});
  //프로필사진
  const [varProfilePhoto, setProfilePhoto] = useState(companyPhoto);
  const [varTaxPhoto, setTaxPhoto] = useState(taxPhoto);
  const [varBrokerPhoto, setBrokerPhoto] = useState(licensePhoto);

  // 이미지 로딩 상태
  const [profilePhotoLoading, setProfilePhotoLoading] = useState(false);
  const [taxPhotoLoading, setTaxPhotoLoading] = useState(false);
  const [brokerPhotoLoading, setBrokerPhotoLoading] = useState(false);

  //업종목록
  const [varBusinessList, setBusinessList] = useState([]);

  //팝업창
  const [varOpenZipcode, setOpenZipcode] = useState(false);
  const [varExpireMember, setExpireMember] = useState(false);
  //타이머
  const [varTime, setTime] = useState(0);
  const [varTimeDisplay, setTimeDIsplay] = useState(0);
  const timerId = useRef(null);
  //휴대폰 인증관련 메뉴 표시 유무
  const [varShowMobile, setShowMobile] = useState(false);
  const [varMobileTimer, setMobileTimer] = useState(false);

  // 중복제출 방지
  const [isSubmitting, setIsSubmitting] = useState(false);

  // 로딩 상태 관리
  const [isLoading, setIsLoading] = useState(false);

  //회원정보 읽어오기
  const getTermInformation = async () => {
    const sendData = { mem_mode: mem_mode, mem_idx: mem_idx };
    await axios.post(`${CONFIG.SERVER_HOST}/_get_member_information`, sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if (datas.retvalue === 1 && datas.results?.length > 0) {
        let members = datas.results[0];
        //담당자 전화번호 분해
        const mobile = ChangeMobileFormat(members.mem_mobile);
        const mobileArray = mobile.split('-');
        members.mem_mobile1 = mobileArray[0];
        members.mem_mobile2 = mobileArray[1];
        members.mem_mobile3 = mobileArray[2];
        //업체 대표번호 분해
        const mcm_phone = ChangeMobileFormat(members.mcm_phone);
        const phoneArray = mcm_phone.split('-');
        members.mcm_phone1 = phoneArray[0];
        members.mcm_phone2 = phoneArray[1];
        members.mcm_phone3 = phoneArray[2];

        //변수에 저장
        setMember(members);
        //사진
        if (members.mcm_image === 1 && members.mcm_file !== "") {
          const sfile = `${CONFIG.SERVER_HOST}/` + GetPhotoImage(members.mcm_file, 'member');
          loadServerImageWithRetry(sfile, setProfilePhoto, setProfilePhotoLoading);
        } else {
          setProfilePhoto(companyPhoto);
        }
        if (members.mcm_tax_image === 1 && members.mcm_tax_file !== "") {
          const taxFile = `${CONFIG.SERVER_HOST}/` + GetPhotoImage(members.mcm_tax_file, 'member');
          loadServerImageWithRetry(taxFile, setTaxPhoto, setTaxPhotoLoading);
        } else {
          setTaxPhoto(taxPhoto);
        }
        if (members.mcm_broker_image === 1 && members.mcm_broker_file !== "") {
          const brokerFile = `${CONFIG.SERVER_HOST}/` + GetPhotoImage(members.mcm_broker_file, 'member');
          loadServerImageWithRetry(brokerFile, setBrokerPhoto, setBrokerPhotoLoading);
        } else {
          setBrokerPhoto(licensePhoto);
        }
      }
    });
  }

  // 서버 이미지 로딩 함수
  // Inside MyPartnerInfo.js
  const loadServerImageWithRetry = (src, setImageState, setLoadingState, retries = 20, delay = 2000) => {
    setLoadingState(true);
    const attempt = () => {
      const cacheBuster = 't=' + new Date().getTime();
      const separator = src.includes('?') ? '&' : '?';
      const img = new Image();
      img.src = src + separator + cacheBuster; // Adjusted here
      img.onload = () => {
        setImageState(src);
        setLoadingState(false);
      };
      img.onerror = () => {
        if (retries > 0) {
          retries--;
          setTimeout(attempt, delay);
        } else {
          setLoadingState(false);
          console.error(`Failed to load image: ${src}`);
        }
      };
    };
    attempt();
  };

  

  // 로컬 이미지 로딩 함수
  const loadLocalImage = (src, setImageState, setLoadingState) => {
    setLoadingState(true);
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImageState(src);
      setLoadingState(false);
    };
    img.onerror = () => {
      setLoadingState(false);
      console.error('Failed to load image');
    };
  };

  //업종읽어오기
  const getBusinessList = async () => {
    await axios.post(`${CONFIG.SERVER_HOST}/_get_business_list`).then((res) => {
      const datas = res.data;
      if (datas.retvalue === 1) {
        setBusinessList(datas.results);
      }
    });
  }

  //로딩 표시 함수
  const showLoading = () => {
    setIsLoading(true);
  }

  const hideLoading = () => {
    setIsLoading(false);
  }

  //최초실행부
  useEffect(() => {
    const chkMember = { mem_mobile: true, mem_certcode: "", mem_cert: "" };
    setCheckMember(chkMember);
    getTermInformation();
    getBusinessList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //페이지 사용자 입력에 따른 조정 함수
  //입력값 처리
  const onChangeInputHandler = (e) => {
    const { name, value } = e.currentTarget;

    if (name === 'mem_mobile1' || name === 'mem_mobile2' || name === 'mem_mobile3') {
      if (varCheckMember.mem_mobile) {
        setCheckMember({ ...varCheckMember, mem_mobile: false });
      }
      setMember({ ...varMember, [name]: OnlyNumerics(value) });
    } else if (name === 'mcm_phone1' || name === 'mcm_phone2' || name === 'mcm_phone3') {
      setMember({ ...varMember, [name]: OnlyNumerics(value) });
    } else if (name === 'mem_cert') {
      setCheckMember({ ...varCheckMember, [name]: value });
    } else {
      setMember({ ...varMember, [name]: value });
    }
  }

  //휴대폰-----------------------------------------------------------------------------------
  //타이머 시작 및 종료
  useEffect(() => {
    if (varMobileTimer) {
      if (varTime > 0) {
        timerId.current = setInterval(() => {
          setTime(prev => prev - 1);
          setTimeDIsplay(varTime);
        }, 1000);
        return () => { clearInterval(timerId.current); }
      } else {
        console.log("타임 아웃", varTime);
        setTime(0); //타이머 마감표시
        setMobileTimer(false);  //타이머 초기화 선언
        setTimeDIsplay(varTime);  //시간표시
        setShowMobile(false); //핸드폰 인증 메뉴 감추기
        clearInterval(timerId.current); //타이머 종료
        setCheckMember({ ...varCheckMember, mem_certcode: "" });  //인증코드 초기화
        alert('입력시간이 초과 되었습니다. 휴대폰 인증을 새로 진행해주세요.');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varTime]);

  //인증절차
  const onSendCertificationHandler = async (e) => {
    if (varMember.mem_mobile1 === "" || varMember.mem_mobile2 === "" || varMember.mem_mobile3 === "") {
      alert('인증할 휴대폰번호를 먼저 입력하세요.'); return false;
    }
    const mem_mobile = varMember.mem_mobile1 + varMember.mem_mobile2 + varMember.mem_mobile3;

    //동일한 전번이 존재하는지 확인
    const sendData = { mem_mode: varMember.mem_mode, mem_mobile: mem_mobile };
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_check_duplicate_mobile`, sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if (datas.retvalue === 1) {
        SendCertificationHandler(mem_mobile);
      } else {
        alert(datas.message);
      }
    });
  }
  const SendCertificationHandler = (mem_mobile) => {
    //인증코드 생성 및 발송
    const certCode = randomStringOnlyNumber(4);
    setCheckMember({ ...varCheckMember, mem_certcode: certCode });
    sendMessageToMobile(mem_mobile, certCode);
    setShowMobile(true);
    //타이머 작동 - display:on
    setMobileTimer(true); //타이머 작동시작
    setTime(180);
  }
  //인증코드 입력후 검증
  const onCheckCertificationHandler = (e) => {
    if (varCheckMember.mem_cert === "") {
      alert('도착한 인증코드를 먼저 입력하세요.'); return false;
    }
    //console.log(varCheckMember.mem_certcode,varMember.mem_cert);
    if (varCheckMember.mem_certcode !== varCheckMember.mem_cert) {
      alert('입력한 코드가 발송한 인증코드와 일치하지 않습니다. 확인후 다시 입력하세요.');
      //인증코드부분 초기화
      setCheckMember({ ...varCheckMember, mem_cert: "" });
      return false;
    }
    //인증완성
    setCheckMember({ ...varCheckMember, mem_mobile: true });
    setMember({ ...varMember, mem_certification: 1 });
    setMobileTimer(false);  //타이머 작동 초기화 선언
    setShowMobile(false); //인증부분 감추기
    clearInterval(timerId.current); //타이머 종료
    alert('휴대폰 본인인증이 되었습니다.');
  }

  //우편번호/주소 검색 팝업창 열기/닫기-------------------------------------------------------------------------------------
  const onOpenZipcodeHandler = (e, bmode) => {
    e.preventDefault();
    setOpenZipcode(bmode);
  };
  //우편번호 선택으로 우편번호/주소와 같이 반환
  const ChangeAddress = (zipcode, address1, regcode, dongName, roadName, locString) => {
    console.log(zipcode, address1, regcode, dongName, roadName, locString);
    setMember((prevState) => { return { ...prevState, mcm_zipcode: zipcode, mcm_address1: address1, mcm_regcode: regcode, mcm_dongname: dongName, mcm_roadname: roadName, mcm_location: locString } });
  };

  //이미지 조정
  const onSelectPhotoHandler = (e, ino) => {
    e.preventDefault();
    if (ino === 1) fileRef1.current.click();
    else if (ino === 2) fileRef2.current.click();
    else if (ino === 3) fileRef3.current.click();
  }
  const onSelectedPhotoHandler = async (e, ino) => {
    e.preventDefault();
    const originalFile = e.currentTarget.files[0];
    
    // HEIC 파일을 JPEG로 변환
    const convertedFile = await convertHeicToJpeg(originalFile);
    
    const objectUrl = URL.createObjectURL(convertedFile);
    if (ino === 1) {
      setEditPhoto(true);
      loadLocalImage(objectUrl, setProfilePhoto, setProfilePhotoLoading);
      setMember((prevState) => { return { ...prevState, mcm_image: 1 } });
    } else if (ino === 2) {
      setEditTax(true);
      loadLocalImage(objectUrl, setTaxPhoto, setTaxPhotoLoading);
      setMember((prevState) => { return { ...prevState, mcm_tax_image: 1 } });
    } else if (ino === 3) {
      setEditBroker(true);
      loadLocalImage(objectUrl, setBrokerPhoto, setBrokerPhotoLoading);
      setMember((prevState) => { return { ...prevState, mcm_broker_image: 1 } });
    }
  }

  //회원등록-----------------------------------------------------------------------------------------------
  const onUpdateMemberHandler = async (e) => {
    if (varMember.mem_name === "") {
      alert('담당자이름을 먼저 입력하세요'); return false;
    } else if (varMember.mem_mobile1 === "" || varMember.mem_mobile2 === "" || varMember.mem_mobile3 === "") {
      alert('담당자 휴대폰 번호를 먼저 입력하세요'); return false;
    } else if (!varCheckMember.mem_mobile) {
      alert('담당자 휴대폰 번호를 먼저 인증하세요'); return false;
    } else if (varMember.mem_zipcode === "") {
      alert('주소를 먼저 선택하세요'); return false;
    } else if (varMember.mcm_name === "") {
      alert('업체명을 먼저 입력하세요'); return false;
    } else if (varMember.mem_name === "") {
      alert('담당자명을 먼저 입력하세요'); return false;
    } else if (varMember.mcm_phone1 === "" || varMember.mcm_phone2 === "" || varMember.mcm_phone3 === "") {
      alert('업체 대표 번호를 먼저 입력하세요'); return false;
    } else if (varMember.mcm_tax_id === "") {
      alert('사업자등록번호를 먼저 입력하세요'); return false;
    } else if (varMember.mcm_tax_email === "") {
      alert('이메일(세금계산서 발행용)을 먼저 입력하세요'); return false;
    } else if (!checkEmailString(varMember.mcm_tax_email)) {
      alert('입력된 이메일(세금계산서 발행용)이 이메일 형식에 부합되지 않습니다.'); return false;
    } else if (varMember.mcm_introduce === "") {
      alert('업체 소개글을 먼저 입력하세요'); return false;
    }

    if (varMember.mem_mode !== 2) {
      if (varMember.mcm_broker_license === "") {
        alert('부동산중개사번호를 먼저 입력해야만 합니다.'); return false;
      }
    }

    if (!window.confirm('수정하신 정보를 저장합니까?')) return false;
    if (isSubmitting) return; // 이미 제출 중이면 중복 제출 방지
    setIsSubmitting(true); // 제출 중으로 변경
    showLoading();

    // 여기서부터 파일 업로드 코드 수정
    if (varEditPhoto || varEditTax || varEditBroker) {
      try {
        // 프로필 사진 처리
        let proFileName = "";
        if (varEditPhoto) {
          const profileImage = document.getElementById('profile');
          if (profileImage.files.length > 0) {
            // HEIC 파일을 JPEG로 변환
            const convertedFile = await convertHeicToJpeg(profileImage.files[0]);
            let formData = new FormData();
            formData.append('member', convertedFile);
            const res = await axios.post(`${CONFIG.SERVER_HOST}/member`, formData);
            if (res.data.retvalue === 1) {
              proFileName = res.data.fileName;
            }
          }
        }

        // 사업자등록증 사진 처리
        let taxFileName = "";
        if (varEditTax) {
          const taxImage = document.getElementById('tax');
          if (taxImage.files.length > 0) {
            // HEIC 파일을 JPEG로 변환
            const convertedFile = await convertHeicToJpeg(taxImage.files[0]);
            let formData = new FormData();
            formData.append('tax', convertedFile);
            const res = await axios.post(`${CONFIG.SERVER_HOST}/tax`, formData);
            if (res.data.retvalue === 1) {
              taxFileName = res.data.fileName;
            }
          }
        }

        // 중개사등록증 사진 처리
        let licenseFileName = "";
        if (varEditBroker) {
          const brokerImage = document.getElementById('broker');
          if (brokerImage.files.length > 0) {
            // HEIC 파일을 JPEG로 변환
            const convertedFile = await convertHeicToJpeg(brokerImage.files[0]);
            let formData = new FormData();
            formData.append('broker', convertedFile);
            const res = await axios.post(`${CONFIG.SERVER_HOST}/broker`, formData);
            if (res.data.retvalue === 1) {
              licenseFileName = res.data.fileName;
            }
          }
        }

        // 모든 파일 업로드 완료 후 회원 정보 등록
        hideLoading();
        RegisterMemberInformation(proFileName, taxFileName, licenseFileName);
      } catch (error) {
        console.error("파일 업로드 중 오류 발생:", error);
        hideLoading();
        setIsSubmitting(false);
        alert("파일 업로드 중 오류가 발생했습니다.");
      }
    } else {
      hideLoading();
      RegisterMemberInformation("", "", "");
    }
  }
  //데이타베이스로 회원정보 등록
  const RegisterMemberInformation = async (proFileName, taxFileName, licenseFileName) => {
    const sendData = { infos: varMember, proFileName: proFileName, taxFileName: taxFileName, licenseFileName: licenseFileName };
    await axios.post(`${CONFIG.SERVER_HOST}/_update_partner_member`, sendData).then((res) => {
      const datas = res.data;
      alert(datas.message);
      if (datas.retvalue === 1) {
        //페이지이동
        navigate(`/MyPage`);
      }
    });
  }

  //회원탈퇴
  const onOpenExpireMemberHandler = (e, bmode) => {
    e.preventDefault();
    onPopupWinHandler(bmode);
    setExpireMember(bmode);
  }
  const onPopupWinHandler = (bmode) => {
    if (bmode) {
      $(".layer-bg").fadeIn("1500");
    } else {
      $(".layer-bg").fadeOut("1500");
    }
  }
  const onExpireMemberHandler = async (e) => {
    /* if(!window.confirm('회원을 탈퇴하시겠습니까?')) return false; */
    const sendData = { mem_idx: mem_idx };
    await axios.post(`${CONFIG.SERVER_HOST}/_expire_member`, sendData).then((res) => {
      const datas = res.data;
      if (datas.retvalue === 1) {
        localStorage.removeItem("mem_idx");
        localStorage.removeItem("mcm_idx");
        localStorage.removeItem("mem_mode");
        localStorage.removeItem("mem_nickname");
        localStorage.removeItem("memberLoginState");
        localStorage.removeItem("mem_admin");
        localStorage.removeItem("autoLogin");
        //페이지이동
        alert(datas.message);
        navigate(`/Login`);
      } else {
        alert(datas.message);
      }
    });
  }

  const onKeyPressHandler = (e, ino) => {
    const { value } = e.currentTarget;
    if (ino === 1) {
      if ((value.length === 3 && value === "010") || value.length === 4) phRef2.current.focus();
    } else if (ino === 2) {
      if (value.length === 4) phRef3.current.focus();
    }
  }
  const onKeyPressMobileHandler = (e, ino) => {
    const { value } = e.currentTarget;
    console.log(ino, value);
    if (ino === 1) {
      if (value.length === 3) hpRef2.current.focus();
    } else if (ino === 2) {
      if (value.length === 4) hpRef3.current.focus();
    }
  }

  return isObjEmpty(varMember) ? null : (
    <>
      {/* 로딩 화면 */}
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-indicator2">
            <div className="loader"></div>
          </div>
        </div>
      )}
      <div className="layer-bg" style={{ backgroundColor: "rgba(0, 0, 0, 1)" }}></div>
      <HeadTitle titleString={"내정보 관리"} />
      <div id="content">
        <div className="pd_wrap04">
          <div className=" join_wrap">
            <div className="center_wrap">
              <div className="form_wrap">
                <div className="tit07">이메일(이메일 주소)</div>
                <div className="has_btn">
                  <input type="text" className="w_s_input disable" name="mem_email" value={varMember.mem_email || ""} readOnly />
                </div>
              </div>
              <div className="form_wrap">
                <div className="tit07">담당자이름&nbsp;<span style={{ color: "red", fontSize: "12px" }}>(필수)</span></div>
                <input type="text" name="mem_name" value={varMember.mem_name || ""} placeholder="회원명을 입력하세요" onChange={e => onChangeInputHandler(e)} />
              </div>
              <div className="form_wrap">
                <div className="tit07">담당자 휴대폰 번호&nbsp;<span style={{ color: "red", fontSize: "12px" }}>(필수)</span></div>
                <div className="ph_num_dan3">
                  <input type="number" pattern="\d*" name="mem_mobile1" ref={hpRef1} value={varMember.mem_mobile1 || ""} maxLength="3" onKeyUp={e => onKeyPressMobileHandler(e, 1)} onChange={e => onChangeInputHandler(e)} />
                  <div className="mid_bar">-</div>
                  <input type="number" pattern="\d*" name="mem_mobile2" ref={hpRef2} value={varMember.mem_mobile2 || ""} maxLength="4" onKeyUp={e => onKeyPressMobileHandler(e, 2)} onChange={e => onChangeInputHandler(e)} />
                  <div className="mid_bar">-</div>
                  <input type="number" pattern="\d*" name="mem_mobile3" ref={hpRef3} value={varMember.mem_mobile3 || ""} maxLength="4" onChange={e => onChangeInputHandler(e)} />&nbsp;
                  <button className="w_s_btn c_white" onClick={e => onSendCertificationHandler(e)}>인증하기</button>
                </div>
                <div className="has_btn" style={varShowMobile ? { display: "block" } : { display: "none" }}>
                  <input type="text" name="mem_cert" value={varCheckMember.mem_cert || ""} placeholder="인증번호를 입력해주세요." onChange={e => onChangeInputHandler(e)} />
                  <button className="w_s_btn" onClick={e => onCheckCertificationHandler(e)}>확인</button>
                </div>
                <div className="fn_timer" style={varShowMobile ? { display: "block" } : { display: "none" }}>{TimeFormat(varTimeDisplay)}</div>
              </div>
              <div className="form_wrap">
                <div className="tit07">업체명&nbsp;<span style={{ color: "red", fontSize: "12px" }}>(필수)</span></div>
                <input type="text" name="mcm_name" value={varMember.mcm_name || ""} placeholder="업체명을 입력하세요" onChange={e => onChangeInputHandler(e)} />
              </div>
              <div className="form_wrap">
                <div className="tit07">업체 대표 번호&nbsp;<span style={{ color: "red", fontSize: "12px" }}>(필수)</span></div>
                <div className="ph_num_dan4">
                  <input type="number" pattern="\d*" name="mcm_phone1" ref={phRef1} value={varMember.mcm_phone1 || ""} maxLength={4} onKeyUp={e => onKeyPressHandler(e, 1)} onChange={e => onChangeInputHandler(e)} />
                  <div className="mid_bar">-</div>
                  <input type="number" pattern="\d*" name="mcm_phone2" ref={phRef2} value={varMember.mcm_phone2 || ""} maxLength={4} onKeyUp={e => onKeyPressHandler(e, 2)} onChange={e => onChangeInputHandler(e)} />
                  <div className="mid_bar">-</div>
                  <input type="number" pattern="\d*" name="mcm_phone3" ref={phRef3} value={varMember.mcm_phone3 || ""} maxLength={4} onChange={e => onChangeInputHandler(e)} />
                </div>
              </div>
              <div className="form_wrap">
                <div className="tit07">사업장 주소&nbsp;<span style={{ color: "red", fontSize: "12px" }}>(필수)</span></div>
                <div className="has_btn" onClick={e => onOpenZipcodeHandler(e, true)}>
                  <input type="text" className="w_s_input disable" name="mcm_zipcode" value={varMember.mcm_zipcode || ""} readOnly />
                  <button className="w_s_btn c_white" onClick={e => onOpenZipcodeHandler(e, true)}>우편번호</button>
                </div>
                <div onClick={e => onOpenZipcodeHandler(e, true)}>
                  <input type="text" className="fix_input" name="mcm_address1" value={varMember.mcm_address1 || ""} readOnly />
                </div>
                <input type="text" className="mt03" name="mcm_address2" value={varMember.mcm_address2 || ""} placeholder="상세주소" onChange={e => onChangeInputHandler(e)} />
              </div>
              <div className="form_wrap">
                <div className="tit07">사업자등록번호&nbsp;<span style={{ color: "red", fontSize: "12px" }}>(필수)</span></div>
                <input type="text" name="mcm_tax_id" value={varMember.mcm_tax_id || ""} placeholder="사업자등록번호를 입력하세요" onChange={e => onChangeInputHandler(e)} />
              </div>
              <div className="form_wrap">
                <div className="tit07">이메일(세금계산서 발행용)&nbsp;<span style={{ color: "red", fontSize: "12px" }}>(필수)</span></div>
                <input type="text" name="mcm_tax_email" value={varMember.mcm_tax_email || ""} placeholder="세금계산서 발행용 이메일을 입력하세요" onChange={e => onChangeInputHandler(e)} />
              </div>
              <div className="form_wrap">
                <div className="tit07">업체 대표사진</div>
                <div className="input_img_wrap">
                  <div className="input_img_con">
                    <div className="img_upload_area">
                      <div className="img_upload">
                        <input type="file" ref={fileRef1} name="profile" id="profile" className="fileUpload" accept={"image/*"} onChange={e => onSelectedPhotoHandler(e, 1)} />
                        <div className="prev_img">
                          {profilePhotoLoading ? (
                            <div className="loading-indicator">
                              <div className="loading-spinner"></div>
                            </div>
                          ) : (
                            <img src={varProfilePhoto} alt="" onClick={(e) => onSelectPhotoHandler(e, 1)} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {varMember.mem_mode === 2 ? null :
                <div className="form_wrap">
                  <div className="tit07">사업자등록증</div>
                  <div className="input_img_wrap">
                    <div className="input_img_con">
                      <div className="img_upload_area">
                        <div className="img_upload">
                          <input type="file" ref={fileRef2} name="tax" id="tax" className="fileUpload" accept={"image/*"} onChange={e => onSelectedPhotoHandler(e, 2)} />
                          <div className="prev_img">
                            {taxPhotoLoading ? (
                              <div className="loading-indicator">
                                <div className="loading-spinner"></div>
                              </div>
                            ) : (
                              <img src={varTaxPhoto} alt="" onClick={(e) => onSelectPhotoHandler(e, 2)} />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
              {varMember.mem_mode === 2 ?
                <div className="form_wrap">
                  <div className="tit07">업체분류</div>
                  <select name="mcm_business" value={varMember.mcm_business} onChange={e => onChangeInputHandler(e)} disabled>
                    <option value={0}>업종선택</option>
                    {varBusinessList.map((biz, i) => (
                      <option key={'biz_' + i} value={biz.vb_idx}>{biz.vb_name}</option>
                    ))}
                  </select>
                </div>
                :
                <>
                  <div className="form_wrap">
                    <div className="tit07">공인중개사 자격증</div>
                    <div className="input_img_wrap">
                      <div className="input_img_con">
                        <div className="img_upload_area">
                          <div className="img_upload">
                            <input type="file" ref={fileRef3} name="broker" id="broker" className="fileUpload" accept={"image/*"} onChange={e => onSelectedPhotoHandler(e, 3)} />
                            <div className="prev_img">
                              {brokerPhotoLoading ? (
                                <div className="loading-indicator">
                                  <div className="loading-spinner"></div>
                                </div>
                              ) : (
                                <img src={varBrokerPhoto} alt="" onClick={(e) => onSelectPhotoHandler(e, 3)} />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form_wrap">
                    <div className="tit07">부동산중개사번호&nbsp;<span style={{ color: "red", fontSize: "12px" }}>(필수)</span></div>
                    <input type="text" name="mcm_broker_license" value={varMember.mcm_broker_license || ""} placeholder="부동산중개사번호를 입력하세요" onChange={e => onChangeInputHandler(e)} />
                  </div>
                </>
              }
              <div className="form_wrap">
                <div className="tit07">업체 소개글&nbsp;<span style={{ color: "red", fontSize: "12px" }}>(필수)</span></div>
                <textarea name="mcm_introduce" cols="30" rows="5" style={{ maxWidth: "100%", minWidth: "100%", width: "100%" }} value={varMember.mcm_introduce || ""}
                  placeholder="이후 장터에 소개될 소개글입니다. 업체에 대한 정보를 간략하게 설명해주세요." onChange={e => onChangeInputHandler(e)} />
              </div>
              <br />
              <div className="bye_btn">
                <button className="goodbye_open" onClick={e => onOpenExpireMemberHandler(e, true)}>탈퇴하기 <img src={arrowRightIcon} alt="" /></button>
              </div>
              <div className="btn_bottom">
                <Link className="btn_style_yes" to={'/Member/EditPWD/2'}>비밀번호 변경</Link>
                <button className="btn_style_no" onClick={e => onUpdateMemberHandler(e)}>정보 저장</button>
              </div>
            </div>
          </div>
          {/*  탈퇴팝업 */}
          <ExpireMember varExpireMember={varExpireMember} onOpenExpireMemberHandler={onOpenExpireMemberHandler} onExpireMemberHandler={onExpireMemberHandler} />
          {/* 우편번호/주소 검색*/}
          <DaumPostCode varOpenZipcode={varOpenZipcode} setOpenZipcode={setOpenZipcode} ChangeAddress={ChangeAddress} onOpenZipcodeHandler={onOpenZipcodeHandler} />
        </div>
      </div>
    </>
  );

}
export default MyPartnerInfo;
