import React, { useState,useEffect,useRef } from "react";
import axios from 'axios';
import CONFIG from '../../../configs/Configs';
import removeImage from "../../images/btn_delete_image.png";
import profilePhoto from "../../../images/sub/@thumnail03.png";

import { ChangeDateStringOnlyDate,SetEmptyInputFile,ChangeMobileFormat,GetPhotoImage,unescapeHtml,convertHeicToJpeg } from '../../../components/common/Common';

function MemberNormal({varMemberInfo,setMemberInfo,onMemberInfoHandler,onOpenZipcodeHandler,onShowBigSizePhotoHandler}) {
  const fileRefProfile= useRef(null);

  //사진이 존재하면 
  const [varProfilePhoto,setProfilePhoto] = useState(profilePhoto);

  useEffect(() => {
    //console.log(varMemberInfo.mem_image,varMemberInfo.mem_file);
    if(varMemberInfo.mem_image === 1 && varMemberInfo.mem_file !== "" && varMemberInfo.mem_file !== "default_member.png") {
      //const fileImage = `${CONFIG.SERVER_HOST}/`+GetPhotoImage(varMemberInfo.mem_file,'member');
      //console.log(fileImage);
      setProfilePhoto(`${CONFIG.SERVER_HOST}/`+GetPhotoImage(varMemberInfo.mem_file,'member'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varMemberInfo]);

  //사진첨부
  const onAddProfilePhotoHandler = (e) => {
    e.preventDefault();
    fileRefProfile.current.click();
  }
  //사진 선택후 화면에서 표시
  const onChangePhotoHandler = async (e) => {
    const fileElement = e.currentTarget;
    const originalFile = fileElement.files[0];
    
    // HEIC 파일을 JPEG로 변환
    const convertedFile = await convertHeicToJpeg(originalFile);
    
    // 화면에 이미지 표시
    setProfilePhoto(URL.createObjectURL(convertedFile));
    // 변수값 재조정
    setMemberInfo((prevState)=>{return {...prevState, mem_image:1}});
  }

  //사진 삭제
  const onDeletePhotoHandler = async (e) => {
    e.preventDefault();
    //파일버튼 선택 초기화
    const fileBox = document.getElementById('selectProfile');
    SetEmptyInputFile(fileBox);
    setProfilePhoto(profilePhoto);

    //정보 배열 적용
    setMemberInfo({...varMemberInfo, mem_image:0,mem_file:''});
  }
  //중복확인
  const onCheckDuplicationHandler = async (e) => {
    if(varMemberInfo.mem_email === "") {
      alert("아이디(이메일주소)를 먼저 입력하세요."); return false;
    }
    const sendData = {checkField:'mem_email',checkString:varMemberInfo.mem_email};
    await axios.post(`${CONFIG.SERVER_HOST}/_check_duplication`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        setMemberInfo({...varMemberInfo,chk_email:1});
      } else {
        setMemberInfo({...varMemberInfo,chk_email:0,mem_email:''});
      }
      alert(datas.message);
    });
  }
  //경고단계
  const onChangeWarningHandler = (e,imode) => {
    setMemberInfo({...varMemberInfo,mem_warning:imode});
  }

  return (
    <div className="main-box">
      <table className="write">
        <colgroup><col width="20%"/><col width="30%"/><col width="20%"/><col width="30%"/></colgroup>
        <thead></thead>
        <tbody>
          <tr>
            <th>아이디(이메일)</th>
            <td>
              <input type="text" className="email-box" style={{width:"68%"}} name="mem_email" value={varMemberInfo.mem_email||""} onChange={(e)=>onMemberInfoHandler(e)}/>
              <button className={varMemberInfo.chk_email===1?"btn line disable":"btn line"} style={{width:"27%"}} onClick={e=>onCheckDuplicationHandler(e)}>중복체크</button>
            </td>
            <th>등록일자</th>
            <td>{varMemberInfo.mem_idx===0?"":ChangeDateStringOnlyDate(varMemberInfo.mem_rdate)}</td>
          </tr>
          <tr>
            <th>비밀번호</th>
            <td><input type="password" className="password-box" name="mem_pwd" value={varMemberInfo.mem_pwd||""} onChange={(e)=>onMemberInfoHandler(e)}/></td>
            <th>비밀번호 재확인</th>
            <td><input type="password" className="password-box" name="mem_pwd2" value={varMemberInfo.mem_pwd2||""} onChange={(e)=>onMemberInfoHandler(e)}/></td>
          </tr>   
          <tr>
            <th>이름</th>
            <td><input type="text" className="name-box" name="mem_name" value={varMemberInfo.mem_name||""} onChange={(e)=>onMemberInfoHandler(e)}/></td>
            <th>닉네임</th>
            <td><input type="text" className="name-box" name="mem_nickname" value={varMemberInfo.mem_nickname||""} onChange={(e)=>onMemberInfoHandler(e)}/></td>
          </tr> 
          <tr>
            <th>주소</th>
            <td colSpan="3">
              <input type="text" name="mem_zipcode" className="zipcode-box" value={varMemberInfo.mem_zipcode||""} readOnly/>
              <button onClick={(e)=>onOpenZipcodeHandler(e,true)} style={{width:"90px"}} className="btn line">우편번호</button><br/>
              <input type="text" name="mem_address1" className="address1-box" value={varMemberInfo.mem_address1||""} readOnly/>&nbsp;&nbsp;
              <input type="text" name="mem_address2" className="address2-box" id={"mem_address2"} value={varMemberInfo.mem_address2||""} 
                      placeholder="상세 주소를 입력해주세요." onChange={(e)=>onMemberInfoHandler(e)}/>
            </td>
          </tr>   
          <tr>
            <th>휴대폰</th>
            <td><input type="text" className="mobile-box" name="mem_mobile" value={ChangeMobileFormat(varMemberInfo.mem_mobile)||""} onChange={(e)=>onMemberInfoHandler(e)}/></td>
            {/* <th>위치(위도,경도)</th>
            <td><input type="text" name="mem_location" value={varMemberInfo.mem_location||""} onChange={(e)=>onMemberInfoHandler(e)}/></td> */}
          </tr>
          <tr>
            <th>회원경고단계</th>
            <td colSpan={3}>
              <input type="checkbox" id="chk1" name="mem_warning" value={0} checked={varMemberInfo.mem_warning===0} onChange={(e)=>onChangeWarningHandler(e,0)} />
              <label htmlFor="chk1" >없음</label>
              <input type="checkbox" id="chk1" name="mem_warning" value={1} checked={varMemberInfo.mem_warning===1} onChange={(e)=>onChangeWarningHandler(e,1)} />
              <label htmlFor="chk1" >1단계</label>
              <input type="checkbox" id="chk2" name="mem_warning" value={2} checked={varMemberInfo.mem_warning===2} onChange={(e)=>onChangeWarningHandler(e,2)} />
              <label htmlFor="chk1" >2단계</label>
              <input type="checkbox" id="chk3" name="mem_warning" value={3} checked={varMemberInfo.mem_warning===3} onChange={(e)=>onChangeWarningHandler(e,3)} />
              <label htmlFor="chk1" >3단계</label>
              <input type="checkbox" id="chk4" name="mem_warning" value={4} checked={varMemberInfo.mem_warning===4} onChange={(e)=>onChangeWarningHandler(e,4)} />
              <label htmlFor="chk1" >4단계</label>
              <input type="checkbox" id="chk5" name="mem_warning" value={5} checked={varMemberInfo.mem_warning===5} onChange={(e)=>onChangeWarningHandler(e,5)} />
              <label htmlFor="chk1" >5단계</label>
            </td>
          </tr> 
          <tr className="image-area">
            <th className="image-title">프로필사진<br/>
              <button className="btn-danger" style={{width:"90px",marginLeft:"0px",marginTop:"10px"}} onClick={(e)=>onAddProfilePhotoHandler(e)}>사진추가</button>
              <input type="file" id="selectProfile" onChange={(e)=>onChangePhotoHandler(e)} accept={"image/*"} ref={fileRefProfile} hidden={true}/>
            </th>
            <td colSpan={3} className="image-block">
              <div className="image-box">
                <img className="btn-delete-image" src={removeImage} alt="" onClick={(e)=>onDeletePhotoHandler(e)} style={varMemberInfo.mem_image===1?{display:'block'}:{display:'none'}}/>
                <img className="request-image" style={{height:"100%"}} src={varProfilePhoto} alt="" onClick={(e)=>onShowBigSizePhotoHandler(e,varProfilePhoto)}/>
              </div>
            </td>  
          </tr>
          <tr>
            <th>관리자 메모</th>
            <td colSpan={3}><textarea name="mem_memo" value={unescapeHtml(varMemberInfo.mem_memo)||""} onChange={(e)=>onMemberInfoHandler(e)}/></td>
          </tr> 
        </tbody>
      </table>
    </div>
  );
}

export default MemberNormal;