/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState,useEffect} from "react"; 
import axios from 'axios';
import CONFIG from '../../../configs/Configs';

import PhotoExistForm from "../../../components/doongji/PhotoExistForm";
import PhotoTempForm from "../../../components/doongji/PhotoTempForm";
import PhotoEmptyForm from "../../../components/doongji/PhotoEmptyForm";
import { convertHeicToJpeg } from "../../../components/common/Common";
const DetailPhotos = ({varMode,photoMax,varProductPhotos,setProductPhotos,varTempProductPhotos,setTempProductPhotos,varTempProductPhotoFiles,
                  setTempProductPhotoFiles,varEmptyProductPhotos,setEmptyProductPhotos,onShowBigSizePhotoHandler}) => {
  const newPhotoData = {file_idx:0,file_name:"",file_preview:""};

  let smessage1 = "",smessage2 = "", delApiName="",photoName="";
  if(varMode === 1) { //둥지
    smessage1 = "선택된 매물사진을 삭제합니까?";
    smessage2 = "선택된 매물사진을 취소합니까?";
    delApiName = "_delete_doongji_photos";
    photoName = "doongji";
  } else if(varMode === 2) {  //장터
    smessage1 = "선택된 장터사진을 삭제합니까?";
    smessage2 = "선택된 장터사진을 취소합니까?";
    delApiName = "_delete_jangter_photos";
    photoName = "jangter";
  } else if(varMode === 3) {  //내 둥지 내놓기
    smessage1 = "선택된 사진을 삭제합니까?";
    smessage2 = "선택된 사진을 취소합니까?";
    delApiName = "_delete_request_photos";
    photoName = "service";
  } else if(varMode === 4) {  //건물월관리
    smessage1 = "선택된 사진을 삭제합니까?";
    smessage2 = "선택된 사진을 취소합니까?";
    delApiName = "_delete_manage_photos";
    photoName = "service";
  }      
  
  //페이지 정보 표시 - 최초
  const [varTotalCountPhotos,setTotalCountPhotos] = useState(0);
  useEffect(() => {
    const serverCount = varProductPhotos?.length;
    const clientCount = varTempProductPhotos?.length;
    const totalCount = serverCount + clientCount;
    setTotalCountPhotos(totalCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //저장된 매물 사진 삭제
  const onDeleteProductPhotoHandler = async (e,file) => {
    e.preventDefault();
    if(!window.confirm(smessage1)) return false;

    //저장된 파일 삭제 
    const delDatasList = varProductPhotos.filter(element=>element.file_idx!==file.file_idx);
    setProductPhotos(delDatasList);

    //데이타베이스에서 삭제
    const sendData = {file_idx:file.file_idx,file_name:file.file_name};
    console.log(delApiName,sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/${delApiName}`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 0){
        alert(datas.message);
      } 
    });

    //추가할 신규 파일 항목 추가
    setEmptyProductPhotos(varEmptyProductPhotos.concat(newPhotoData));
  }

  //임시저장배열에서 매물사진 삭제
  const onDeleteTempProductPhotoHandler = (e,tidx) => {
    console.log(tidx);
    e.preventDefault();
    if(!window.confirm(smessage2)) return false;

    //임시 저장 배열에서 삭제 
    let CopyList = [...varTempProductPhotos];
    CopyList.splice(tidx,1);
    setTempProductPhotos(CopyList);

    //임시 저장 파일 배열에 반영
    let CopyFileList = [...varTempProductPhotoFiles];
    CopyFileList.splice(tidx,1);
    setTempProductPhotoFiles(CopyFileList);

    //추가할 신규 파일 항목 추가
    setEmptyProductPhotos(varEmptyProductPhotos.concat(newPhotoData));
  }

  //매물 사진 추가 
  const onSelectedPhotoHandler = async (e) => {
    console.log(e.currentTarget.files);
    const imageLists = e.currentTarget.files;
    
    var PhotoFiles = [];
    var PhotoList = [];

    for (var i = 0; i < imageLists.length; i++) {
      console.log(imageLists[i]);
      
      // HEIC 파일 변환 처리
      const file = await convertHeicToJpeg(imageLists[i]);
      
      //임시저장배열에 추가 --- 미리보기
      let newPhoto = {...newPhotoData};
      newPhoto.file_preview = URL.createObjectURL(file);
      PhotoList.push(newPhoto);

      //임시 파일 저장 배열  --- 파일정보
      var PhotoForm = new FormData();
      PhotoForm.append(photoName, file);
      PhotoFiles.push(PhotoForm);
    }
    setTempProductPhotos(varTempProductPhotos.concat(PhotoList));
    setTempProductPhotoFiles(varTempProductPhotoFiles.concat(PhotoFiles));
    
    //신규저장배열에서 빈공간 ?개 삭제
    let CopyList = [...varEmptyProductPhotos];
    CopyList.splice(0,imageLists.length);
    setEmptyProductPhotos(CopyList);
  }

  return (
    <>
      {/* 등록 가능한 빈 매물 사진 영역 */}
      {varTotalCountPhotos >= photoMax?null:
        <PhotoEmptyForm onSelectedPhotoHandler={onSelectedPhotoHandler}/>
      }
      {/* 이미 등록된 매물 사진 */}
      {varProductPhotos.map((photo,i)=>(
        <PhotoExistForm key={'existphoto_'+i} photo={photo} varMode={varMode} 
            onDeleteProductPhotoHandler={onDeleteProductPhotoHandler} onShowBigSizePhotoHandler={onShowBigSizePhotoHandler}/>
      ))}
      {/* 지금 막 등록한 임시 매물 사진 - 서버에 아직 등록 안한 경우 */}
      {varTempProductPhotos?.length===0?null
        :varTempProductPhotos.map((tempphoto,tidx)=>(
        <PhotoTempForm key={'emptyphoto_'+tidx} idx={tidx} photo={tempphoto} 
        onDeleteTempProductPhotoHandler={onDeleteTempProductPhotoHandler} onShowBigSizePhotoHandler={onShowBigSizePhotoHandler}/>
      ))}

    </>
  );
}
export default DetailPhotos;