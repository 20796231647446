import React, { useState,useEffect,useRef } from "react"; 
import DoongjiProduct from "./DoongjiProduct";
import { isNull } from "../../components/common/Common";
import reloadImage from '../../images/reload.svg';
import filterImage from '../../images/icon_filter_w.svg';
import ringArrowIcon from '../../images/icon-rightarrow.png';

const SearchedResult = ({
    varSortingList,
    varFilterOptions,
    setFilterOptions,
    varTotalCount,
    varDoongjiList,
    setDoongjiList,
    varFixedFilterList,
    getFilterOptions,
    onSelectFilterHandler,
    onPopupFilterHandler,
    FilterReloading,
    varMorePage,
    varPageNum,
    setPageNum,
    onReloadHandler
}) => {
  const [varShowList,setShowList] = useState([]);
  const [varShowCount,setShowCount] = useState(0);

  const scrollRef = useRef([]);

  // 초기 로드시
  useEffect(() => {
    // 체크박스 설정 (rp_mode=3은 '일반매물+중개매물' 둘 다 선택)
    if(varFilterOptions.rp_mode === 3) {
      if(document.getElementById('rp_mode1')) {
        document.getElementById('rp_mode1').checked = true;  // 일반매물
      }
      if(document.getElementById('rp_mode2')) {
        document.getElementById('rp_mode2').checked = true;  // 중개매물
      }
    } else {
      if(document.getElementById('rp_mode1')) {
        document.getElementById('rp_mode1').checked = (varFilterOptions.rp_mode === 1);
      }
      if(document.getElementById('rp_mode2')) {
        document.getElementById('rp_mode2').checked = (varFilterOptions.rp_mode === 2);
      }
    }

    // 현재 받아온 매물 그대로 노출
    setShowList(varDoongjiList);
    setShowCount(varTotalCount);
  }, [varDoongjiList, varTotalCount, varFilterOptions.rp_mode, varFixedFilterList]);

  // varFilterOptions 변경 시 페이지를 1로 초기화하여 재검색
  useEffect(() => {
    if (Object.keys(varFilterOptions).length > 0) {
      FilterReloading();
    }
  }, [varFilterOptions, FilterReloading]);

  // 목록이 바뀔 때마다 스크롤 위치 복원
  useEffect(() => {
    ResetScrollTop();
  }, [varShowList]);

  // 스크롤 위치 복원
  const ResetScrollTop = () => {
    if(!isNull(localStorage.getItem('scrollIndex')) 
      && localStorage.getItem('scrollIndex') !== '-1'){
      const inum  = localStorage.getItem('scrollIndex');
      const iTop  = localStorage.getItem('scrollTop');
      const saleBlock = scrollRef.current[inum];
      if(!isNull(saleBlock)) { 
        setTimeout(()=>window.scroll({top:iTop,left:0}),10);
        localStorage.setItem('scrollIndex',-1);
      }
    }
  }

  // 등록유형 변경시- 1:일반매물/2:중개매물/3:전체
  const onChangeListHandler = (e) => {
    // 체크박스 직접 접근하기 전에 존재 여부 확인
    const checkBox1 = document.getElementById('rp_mode1');
    const checkBox2 = document.getElementById('rp_mode2');
    
    // null 체크 후 상태 확인
    const bmode1 = checkBox1 && checkBox1.checked;
    const bmode2 = checkBox2 && checkBox2.checked;
    
    let iset = 0;
    if(bmode1) iset += 1;  // 일반매물
    if(bmode2) iset += 2;  // 중개매물

    // 현재 상태를 setFilterOptions로 업데이트하고,
    // 직접 FilterReloading 호출하여 데이터 리로드
    setFilterOptions(prevOptions => {
      const newOptions = {
        ...prevOptions,
        rp_mode: iset
      };
      
      // 다음 렌더링 사이클에서 FilterReloading이 호출되도록 setTimeout 사용
      setTimeout(() => {
        if(iset > 0) {  // 최소 하나 이상의 체크박스가 선택된 경우만 리로딩
          FilterReloading();
        }
      }, 0);
      
      return newOptions;
    });
    
    // 모든 체크박스가 해제된 경우 빈 목록 표시
    if(iset === 0) {
      setShowList([]);
      setShowCount(0);
    }
  }

  // 쏘팅항목 선택시
  const onChangeSortHandler = (e) => {
    const { value } = e.currentTarget;
    // 여기서 setFilterOptions에 정렬값을 반영
    setFilterOptions({
      ...varFilterOptions,
      filters: value
    });
  }

  // 슬라이드 버튼 (필터 목록 가로 스크롤)
  const onMoveFilterOption = (e) => {
    const filterareaBox = document.getElementById('filter_lists');
    const scrollX = filterareaBox.scrollLeft;
    let newScroollX = scrollX + 80;
    if(newScroollX > 473) newScroollX = 473;
    filterareaBox.scrollLeft = newScroollX;
  }

  return (
    <section>
      <div className="main_dunggi_wrap">
        <div className="filter_type01_wrap">
          <div className="center_wrap">
            <div className="tit02">
              둥지 <span className="red_txt"><span className="gray_bar">&nbsp;</span>{varShowCount} 건</span>
              {/* 전체 필터 팝업창 오픈 */}
              <div className="filter02_open_btn go_right" onClick={e=>onPopupFilterHandler(e,true)}>
                <span>상세필터</span>&nbsp;
                <img src={filterImage} alt="필터오픈버튼"/>
              </div>
            </div>
            {/* 매물등록유형 선택 */}
            <ul className="filter_opt01">
              <li className="terms_chks">
                <input type="checkbox" name="rp_mode" className="chk1" id="rp_mode2" onChange={onChangeListHandler}/>
                <i></i><label htmlFor="rp_mode2">중개매물</label>
              </li>
              <li className="terms_chks">
                <input type="checkbox" name="rp_mode" className="chk1" id="rp_mode1" onChange={onChangeListHandler}/>
                <i></i><label htmlFor="rp_mode1">일반매물</label>
              </li>
              {/* 목록 정렬을 위한 항목 */}
              <li className="sort_box">
                <select name="sorting" id="sorting" value={varFilterOptions.filters} onChange={onChangeSortHandler}>
                  {varSortingList.map((sorts,i)=>(
                    <option key={'sorts_'+i} value={sorts.vs_idx}>
                      {sorts.vs_name}
                    </option>
                  ))}
                </select>
              </li>
            </ul>
          </div>
        </div>

        {/* 필터 카테고리(가로 스크롤) */}
        <div className="filter_ctg_wrap mostly-customized-scrollbar">
          <img 
            src={reloadImage} 
            alt="" 
            className="filter-left-init" 
            onClick={onReloadHandler}
          />
          <ul className="filter_ctg" id="filter_lists">
            {varFixedFilterList.map((filter,i)=>(
              <li key={"filter_btn_"+i} 
                  id={'filter_btn_'+filter.vsm_idx} 
                  className={
                    filter.vsm_show===0 
                      ? "hidden" 
                      : (filter.vsm_select===0 ? "" : "active")
                  }
              >
                <button className="mini_ft_open01" onClick={e=>onSelectFilterHandler(e,filter.vsm_idx)}>
                  {filter.vsm_name}
                </button>
              </li>
            ))}
          </ul>
          <div className="grad_arrow">
            <img 
              src={ringArrowIcon} 
              className="filter-right-move" 
              alt="" 
              onClick={onMoveFilterOption}
            />
          </div>
        </div>
      </div>

      {/* 둥지매물목록 */}
      <div className="main_dunggi list_type01" id="product_list">
        {varShowList.map((product,i)=>(
          <div className="list_con" key={'product_'+i} ref={el => (scrollRef.current[i]=el)}>
            <DoongjiProduct 
              inum={i} 
              product={product} 
              varDoongjiList={varDoongjiList} 
              setDoongjiList={setDoongjiList}
            />
          </div>
        ))}
        {/* 더보기 버튼 */}
        {varMorePage && 
          <div 
            className="list_con more"  
            onClick={()=>setPageNum(prev=>prev+1)}
          >
            {/* 더보기 */}
          </div>
        }
      </div>
    </section>    
  );
};

export default SearchedResult;
