/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';

import axios from 'axios';
import CONFIG from '../../configs/Configs';

import { TimeFormat, sendMessageToMobile, isObjEmpty, ChangeMobileFormat, GetPhotoImage, randomStringOnlyNumber, convertHeicToJpeg } from '../../components/common/Common';
import HeadTitle from '../../components/include/HeadTitle';
import DaumPostCode from "../../components/popup/DaumPostCode";

import arrowRightIcon from "../../images/arrow_right_on.png";
import profilePhoto from "../../images/sub/@thumnail03.png";

const MyInfo = () => {
  const mem_idx = Number(localStorage.getItem("mem_idx"));
  const mem_mode = Number(localStorage.getItem("mem_mode"));
  localStorage.setItem("foundIdx", mem_idx); // 패스워드변경시 필요
  const navigate = useNavigate();
  // 파일관련
  const fileRef = useRef(null);
  // 전화번호
  const hpRef1 = useRef(null);
  const hpRef2 = useRef(null);
  const hpRef3 = useRef(null);

  const [varEditPhoto, setEditPhoto] = useState(false);

  // 회원정보
  const [varMember, setMember] = useState({});
  // 인증정보
  const [varCheckMember, setCheckMember] = useState({});
  // 프로필사진
  const [varProfilePhoto, setProfilePhoto] = useState(profilePhoto);
  const [profilePhotoLoading, setProfilePhotoLoading] = useState(false); // Loading state

  // 팝업창
  const [varOpenZipcode, setOpenZipcode] = useState(false);

  // 타이머
  const [varTime, setTime] = useState(0);
  const [varTimeDisplay, setTimeDIsplay] = useState(0);
  const timerId = useRef(null);
  // 휴대폰 인증관련 메뉴 표시 유무
  const [varShowMobile, setShowMobile] = useState(false);
  const [varMobileTimer, setMobileTimer] = useState(false);

  // 중복제출 방지
  const [isSubmitting, setIsSubmitting] = useState(false);

  // 로딩 상태 관리
  const [isLoading, setIsLoading] = useState(false);

  // 회원정보 읽어오기
  const getTermInformation = async () => {
    const sendData = { mem_mode: mem_mode, mem_idx: mem_idx };
    await axios.post(`${CONFIG.SERVER_HOST}/_get_member_information`, sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if (datas.retvalue === 1 && datas.results?.length > 0) {
        let members = datas.results[0];
        const mobile = ChangeMobileFormat(members.mem_mobile);
        const mobileArray = mobile.split('-');
        members.mem_mobile1 = mobileArray[0];
        members.mem_mobile2 = mobileArray[1];
        members.mem_mobile3 = mobileArray[2];
        // 사진
        if (members.mem_image === 1) {
          const imageUrl = `${CONFIG.SERVER_HOST}/` + GetPhotoImage(members.mem_file, 'member');
          loadServerImageWithRetry(imageUrl);
        }
        setMember(members);
      }
    });
  };

  // Function to load server image with retry
  const loadServerImageWithRetry = (src, retries = 20, delay = 2000) => {
    setProfilePhotoLoading(true);
    const attempt = () => {
      const img = new Image();
      img.src = src + '&t=' + new Date().getTime(); // Cache buster
      img.onload = () => {
        setProfilePhoto(src);
        setProfilePhotoLoading(false);
      };
      img.onerror = () => {
        if (retries > 0) {
          retries--;
          setTimeout(attempt, delay);
        } else {
          setProfilePhotoLoading(false);
          console.error(`Failed to load image: ${src}`);
        }
      };
    };
    attempt();
  };

  // Function to load local image
  const loadLocalImage = (src) => {
    setProfilePhotoLoading(true);
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setProfilePhoto(src);
      setProfilePhotoLoading(false);
    };
    img.onerror = () => {
      setProfilePhotoLoading(false);
      console.error('Failed to load image');
    };
  };
  
  //로딩 표시 함수
  const showLoading = () => {
    setIsLoading(true);
  }

  const hideLoading = () => {
    setIsLoading(false);
  }
  // 최초실행부
  useEffect(() => {
    const chkMember = { mem_mobile: true, mem_nickname: true, mem_certcode: "", mem_cert: "" };
    setCheckMember(chkMember);
    getTermInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 페이지 사용자 입력에 따른 조정 함수
  // 입력값 처리
  const onChangeInputHandler = (e) => {
    const { name, value } = e.currentTarget;

    if (name === 'mem_nickname') {
      if (varCheckMember[name]) {
        setCheckMember({ ...varCheckMember, [name]: false });
      }
    } else if (name === 'mem_mobile1' || name === 'mem_mobile2' || name === 'mem_mobile3') {
      if (varCheckMember.mem_mobile) {
        setCheckMember({ ...varCheckMember, mem_mobile: false });
      }
    }
    if (name === 'mem_cert') {
      setCheckMember({ ...varCheckMember, [name]: value });
    } else {
      setMember({ ...varMember, [name]: value });
    }
  };
  // 중복확인
  const onCheckDuplicationHandler = async (e, soption) => {
    if (varMember[soption] === "") {
      let smess = "아이디(이메일주소)를 먼저 입력하세요.";
      if (soption === "mem_nickname") smess = "닉네임을 먼저 입력하세요.";
      alert(smess); return false;
    }
    const sendData = { checkField: soption, checkString: varMember[soption] };
    await axios.post(`${CONFIG.SERVER_HOST}/_check_duplication`, sendData).then((res) => {
      const datas = res.data;
      if (datas.retvalue === 1) {
        setCheckMember({ ...varCheckMember, [soption]: true });
      } else {
        setMember({ ...varMember, [soption]: "" });
      }
      alert(datas.message);
    });
  };

  useEffect(() => {
    if(varMobileTimer) {
      if (varTime > 0) {
        timerId.current = setInterval(() => {
          setTime(prev => prev - 1);
          setTimeDIsplay(varTime);
        }, 1000);
        return () => {clearInterval(timerId.current);}
      } else {
        console.log("타임 아웃",varTime);
        setTime(0); //타이머 마감표시
        setMobileTimer(false);  //타이머 초기화 선언
        setTimeDIsplay(varTime);  //시간표시
        setShowMobile(false); //핸드폰 인증 메뉴 감추기
        clearInterval(timerId.current); //타이머 종료
        setCheckMember({...varCheckMember,mem_certcode:""});  //인증코드 초기화
        alert('입력시간이 초과 되었습니다. 휴대폰 인증을 새로 진행해주세요.');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varTime]);

  //인증절차
  const onSendCertificationHandler = async(e) => {
    if(varMember.mem_mobile1==="" || varMember.mem_mobile2==="" || varMember.mem_mobile3==="") {
      alert('인증할 휴대폰번호를 먼저 입력하세요.'); return false;
    }
    const mem_mobile = varMember.mem_mobile1+varMember.mem_mobile2+varMember.mem_mobile3;
    //동일한 전번이 존재하는지 확인
    const sendData = {mem_mode:varMember.mem_mode, mem_mobile:mem_mobile};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_check_duplicate_mobile`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1) {
        SendCertificationHandler(mem_mobile);
      } else {
        alert(datas.message);
      }
    });
  }
  const SendCertificationHandler = (mem_mobile) => {       
    //인증코드 생성 및 발송
    const certCode = randomStringOnlyNumber(4);
    setCheckMember({...varCheckMember,mem_certcode:certCode});
    sendMessageToMobile(mem_mobile,certCode);
    setShowMobile(true);
    //타이머 작동 - display:on
    setMobileTimer(true); //타이머 작동시작
    setTime(180);
  }
  //인증코드 입력후 검증
  const onCheckCertificationHandler = (e) => {
    if(varCheckMember.mem_cert === "") {
      alert('도착한 인증코드를 먼저 입력하세요.'); return false;
    }
    //console.log(varCheckMember.mem_certcode,varMember.mem_cert);
    if(varCheckMember.mem_certcode !== varCheckMember.mem_cert) {
      alert('입력한 코드가 발송한 인증코드와 일치하지 않습니다. 확인후 다시 입력하세요.'); 
      //인증코드부분 초기화
      setCheckMember({...varCheckMember,mem_cert:""});
      return false;
    }
    //인증완성
    setCheckMember({...varCheckMember,mem_mobile:true});
    setMember({...varMember,mem_certification:1});
    setMobileTimer(false);  //타이머 작동 초기화 선언
    setShowMobile(false); //인증부분 감추기
    clearInterval(timerId.current); //타이머 종료
    alert('휴대폰 본인인증이 되었습니다.');
  }

  const onKeyPressHandler = (e,ino) => {
    const { value } = e.currentTarget;
    if(ino === 1) {
      if(value.length === 3) hpRef2.current.focus();
    } else if(ino === 2) {
      if(value.length === 4) hpRef3.current.focus();
    }
  }

  //우편번호/주소 검색 팝업창 열기/닫기-------------------------------------------------------------------------------------
  const onOpenZipcodeHandler = (e, bmode) => {
    e.preventDefault();
    console.log(varMember);
    setOpenZipcode(bmode);
  };
  //우편번호 선택으로 우편번호/주소와 같이 반환
  const ChangeAddress = (zipcode,fullAddr,regcode,dongName,roadName,locString) => {
    console.log(zipcode,fullAddr,regcode,dongName,roadName,locString);
    //아래 함수가 실행되기전에 주소가 선택되면 초기화됨
    setMember({...varMember,mem_zipcode:zipcode,mem_address1:fullAddr,mem_regcode:regcode,mem_dongname:dongName,mem_roadname:roadName,mem_location:locString});
  };

  // 프로필사진
  const onSelectPhotoHandler = (e) => {
    e.preventDefault();
    fileRef.current.click();
  };
  const onSelectedPhotoHandler = async (e) => {
    e.preventDefault();
    const originalFile = e.currentTarget.files[0];
    
    // HEIC 파일을 JPEG로 변환
    const convertedFile = await convertHeicToJpeg(originalFile);
    
    const objectUrl = URL.createObjectURL(convertedFile);
    loadLocalImage(objectUrl);
    setEditPhoto(true);
    setMember((prevState) => ({ ...prevState, mem_image: 1 }));
  };

  const onUpdateMemberHandler = async(e) => {
    if (varMember.mem_name === "") {
      alert('회원명을 먼저 입력하세요'); return false;
    } else if (varMember.mem_nickname === "") {
      alert('회원닉네임을 먼저 입력하세요'); return false;
    } else if (!varCheckMember.mem_nickname) {
      alert('닉네임의 중복검사를 먼저 실행하세요'); return false;    
    } else if (varMember.mem_mobile1 === "" || varMember.mem_mobile2 === "" || varMember.mem_mobile3 === "") {
      alert('휴대폰 번호를 먼저 입력하세요'); return false;      
    } else if (!varCheckMember.mem_mobile) {
      alert('휴대폰번호를 먼저 인증하세요'); return false;  
    } else if (varMember.mem_zipcode === "") {
      alert('주소를 먼저 선택하세요'); return false;  
    } 

    if(!window.confirm('수정하신 정보를 저장합니까?')) return false;
    if (isSubmitting) return; // 이미 제출 중이면 중복 제출 방지
    setIsSubmitting(true); // 제출 중으로 변경
    showLoading();
    
    if(varEditPhoto) {
      const profileImage = document.getElementById('profile');
      console.log(profileImage.files[0].name);
      
      // HEIC 파일을 JPEG로 변환
      const convertedFile = await convertHeicToJpeg(profileImage.files[0]);
      
      let formData = new FormData();
      formData.append('member', convertedFile);
      await axios.post(`${CONFIG.SERVER_HOST}/member`, formData).then((res) => {
        console.log(res.data);
        if(res.data.retvalue === 1) {
          hideLoading();
          RegisterMemberInformation(res.data.fileName);
        }
      });
    } else {
      hideLoading();
      RegisterMemberInformation("");
    }
  }
  //데이타베이스로 회원정보 등록
  const RegisterMemberInformation = async (fileName) => {
    console.log(fileName);
    const sendData = {infos:varMember,fileName:fileName};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_update_normal_member`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        localStorage.setItem("mem_nickname", varMember.mem_nickname);
        //페이지이동
        alert(datas.message);
        navigate(`/MyPage`);
      } else {
        alert(datas.message);
      }
    });
  }
  //회원탈퇴
  const onExpireMemberHandler = async (e) => {
    if(!window.confirm('회원을 탈퇴하시겠습니까?')) return false;
    let mcm_idx = 0;
    if(mem_mode > 0) mcm_idx = localStorage.getItem("mcm_idx");
    const sendData = {mem_idx:mem_idx,mcm_idx:mcm_idx};
    await axios.post(`${CONFIG.SERVER_HOST}/_expire_member`,sendData).then((res) => {
      const datas = res.data;
      if(datas.retvalue === 1) {
        localStorage.removeItem("mem_idx");
        localStorage.removeItem("mcm_idx");
        localStorage.removeItem("mem_mode");
        localStorage.removeItem("mem_nickname");
        localStorage.removeItem("memberLoginState");
        localStorage.removeItem("mem_admin");
        localStorage.removeItem("autoLogin");

        localStorage.removeItem('DoongjiSearchingString');
        localStorage.removeItem('DoongjiSearchRegionList');
        localStorage.removeItem('DoongjiFixedFilterList');
        localStorage.removeItem('DoongjiFixedFilterOptionList');
        localStorage.removeItem('DoongjiFilterOptions');
        localStorage.removeItem('DoongjiSortingList');

        localStorage.removeItem('JangterSearchingString');
        localStorage.removeItem('JangterSearchRegionList');

        localStorage.removeItem('PartnerSearchingString');
        localStorage.removeItem('PartnerSearchRegionList');

        alert(datas.message);
        //페이지이동
        navigate(`/Login`);
      } else {
        alert(datas.message);
      }
    });
  }

  return isObjEmpty(varMember) ? null : (
    <>
      {/* 로딩 화면 */}
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-indicator2">
            <div className="loader"></div>
          </div>
        </div>
      )}
      <HeadTitle titleString={"내정보 관리"} />
      <div id="content">
        <div className="pd_wrap04">
          <div className="join_wrap">
            <div className="center_wrap">
            <div className="form_wrap">
                <div className="tit07">이메일(이메일 주소)</div>
                <div className="has_btn">
                  <input type="text" className="w_s_input disable" name="mem_email" value={varMember.mem_email} readOnly/>
                </div>
              </div>
              <div className="form_wrap">
                <div className="tit07">이름</div>
                <input type="text" name="mem_name" value={varMember.mem_name} placeholder="회원명을 입력하세요" onChange={e=>onChangeInputHandler(e)}/>
              </div>
              <div className="form_wrap">
                <div className="tit07">닉네임</div>
                <div className="has_btn">
                  <input type="text" className="w_s_input" name="mem_nickname" value={varMember.mem_nickname||""} placeholder="닉네임을 입력하세요" onChange={e=>onChangeInputHandler(e)}/>
                  <button className="w_s_btn" onClick={e=>onCheckDuplicationHandler(e,'mem_nickname')}>중복체크</button>
                </div>
              </div>
              <div className="form_wrap">
                <div className="tit07">휴대폰 번호</div>
                <div className="ph_num_dan3">
                  <input type="number" pattern="\d*" name="mem_mobile1" ref={hpRef1} value={varMember.mem_mobile1} maxLength="3" onKeyUp={e=>onKeyPressHandler(e,1)} onChange={e=>onChangeInputHandler(e)}/>
                  <div className="mid_bar">-</div>
                  <input type="number" pattern="\d*" name="mem_mobile2" ref={hpRef2} value={varMember.mem_mobile2} maxLength="4" onKeyUp={e=>onKeyPressHandler(e,2)} onChange={e=>onChangeInputHandler(e)}/>
                  <div className="mid_bar">-</div>
                  <input type="number" pattern="\d*" name="mem_mobile3" ref={hpRef3} value={varMember.mem_mobile3} maxLength="4" onKeyUp={e=>onKeyPressHandler(e,3)} onChange={e=>onChangeInputHandler(e)}/>&nbsp;
                  <button className="w_s_btn c_white" onClick={e=>onSendCertificationHandler(e)}>인증하기</button>
                </div>
                <div className="has_btn" style={varShowMobile?{display:"block"}:{display:"none"}}>
                  <input type="text" name="mem_cert" value={varCheckMember.mem_cert} placeholder="인증번호를 입력해주세요." onChange={e=>onChangeInputHandler(e)}/>
                  <button className="w_s_btn" onClick={e=>onCheckCertificationHandler(e)}>확인</button>
                </div>
                <div className="fn_timer" style={varShowMobile?{display:"block"}:{display:"none"}}>{TimeFormat(varTimeDisplay)}</div>
              </div>
              <div className="form_wrap">
                <div className="tit07">주소</div>
                <div className="has_btn" onClick={e=>onOpenZipcodeHandler(e,true)}>
                  <input type="text" className="w_s_input disable" name="mem_zipcode" value={varMember.mem_zipcode||""} readOnly/>
                  <button className="w_s_btn c_white" onClick={e=>onOpenZipcodeHandler(e,true)}>우편번호</button>
                </div>
                <div onClick={e=>onOpenZipcodeHandler(e,true)}>
                  <input type="text" className="fix_input" name="mem_address1" value={varMember.mem_address1||""} readOnly/>
                </div>
                <input type="text" name="mem_address2" value={varMember.mem_address2||""} placeholder="상세주소" onChange={e=>onChangeInputHandler(e)}/>
              </div>
              <div className="form_wrap">
                <div className="tit07">프로필사진</div>
                <div className="input_img_wrap">
                  <div className="input_img_con">
                    <div className="img_upload_area">
                      <div className="img_upload">
                        <input type="file" ref={fileRef} name="profile" id="profile" className="fileUpload" accept={"image/*"} placeholder="이미지를 첨부해주세요" onChange={e => onSelectedPhotoHandler(e)} />
                        <div className="prev_img">
                          {profilePhotoLoading ? (
                            <div className="loading-indicator">
                              <div className="loading-spinner"></div>
                            </div>
                          ) : (
                            <img src={varProfilePhoto} alt="" onClick={(e) => onSelectPhotoHandler(e)} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bye_btn">
                <button className="goodbye_open" onClick={e=>onExpireMemberHandler(e)}>탈퇴하기 <img src={arrowRightIcon} alt=""/></button>
              </div>
              <div className="btn_bottom">
                <Link className="btn_style_yes" to={'/Member/EditPWD/1'}>비밀번호 변경</Link>
                <button className="btn_style_no" onClick={e=>onUpdateMemberHandler(e)}>정보 저장</button>
              </div>
            </div>
          </div>
        </div>
        {/* 우편번호/주소 검색*/}
        <DaumPostCode varOpenZipcode={varOpenZipcode} setOpenZipcode={setOpenZipcode} ChangeAddress={ChangeAddress} />
      </div>
    </>
  );
};

export default MyInfo;
