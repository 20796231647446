import React, { useState, useEffect, useRef, useCallback } from "react"; 
import { DESC, isNull } from "../common/Common";
import PartnerProduct from "../../components/jangter/PartnerProduct";

//파트너스 검색 목록
const SearchedPartnerResult = ({varBusiness, varTotalCount, varPartnerList, setPartnerList, varMorePage, varPageNum, setPageNum}) => {
  
  const scrollRef = useRef([]);
  const loadMoreRef = useRef(null); // 무한 스크롤을 위한 참조
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태
  const [varShowList, setShowList] = useState([]);

  // 페이지 번호 증가 함수 (더보기 버튼용 및 무한 스크롤용)
  const handleLoadMore = useCallback(() => {
    if (isLoading || !varMorePage) return;
    
    console.log("파트너스 더보기 실행, 페이지 증가:", varPageNum + 1);
    setIsLoading(true);
    setPageNum(prevPageNum => prevPageNum + 1);
    
    // 로딩 상태 해제 (데이터 로드 후 자동으로 해제됨)
    setTimeout(() => setIsLoading(false), 1000);
  }, [varPageNum, isLoading, varMorePage, setPageNum]);
  
  // 스크롤 이벤트 핸들러
  const handleScroll = useCallback(() => {
    if (!varMorePage || isLoading) return;
    
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const clientHeight = document.documentElement.clientHeight;
    
    // 스크롤이 페이지 하단에 도달했는지 확인 (하단에서 200px 위치에 도달하면 로드)
    if (scrollHeight - scrollTop - clientHeight < 200) {
      console.log("파트너스 스크롤이 하단에 도달, 더 많은 데이터 로드");
      handleLoadMore();
    }
  }, [varMorePage, isLoading, handleLoadMore]);
  
  // 스크롤 이벤트 리스너 등록 및 해제
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  //페이지 정보 표시 - 최초
  useEffect(() => {
    setShowList(varPartnerList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPartnerList]);

  //쏘팅항목 선택시
  const onChangeSortHandler = (e) => {
    const { value } = e.currentTarget;
    let arrayList = [...varPartnerList];
    let fname = "jp_rdate";  //최신등록순
    let fmode = true;
    if(Number(value)===2) { //인기순 = 조회수 믾은 것부터
      fname = "jp_hits";
      fmode = false;
    } 
    arrayList.sort(DESC(fname,fmode));
    setPartnerList(arrayList);
  }

  useEffect(() => {
    ResetScrollTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varShowList]);
  
  //선택되었던 매물로 이동
  const ResetScrollTop = () => {
    if(!isNull(localStorage.getItem('scrollIndex')) && localStorage.getItem('scrollIndex')!== -1){
      const inum  = localStorage.getItem('scrollIndex');
      const iTop  = localStorage.getItem('scrollTop');
      const saleBlock = scrollRef.current[inum];
      if(!isNull(saleBlock)) { 
        setTimeout(()=>window.scroll({top:iTop,left:0}),10);
        localStorage.setItem('scrollIndex',-1);
      }
    }
  }

  return (
    <section>
      <div className="tit01 st02 mk_page_title">
        <div className="center_wrap">{varBusiness}</div>
      </div>
      <div className="mk_con_wrap">
        <div className="filter_type02_wrap">
          <div className="center_wrap">
            <div className="tit01 st02">파트너스
              <span className="red_txt"><span className="gray_bar">&nbsp;</span>{varTotalCount+" 건"}</span>
              <div className="go_right">
                {/* 목록 정렬을 위한 항목 */}
                <li className="sort_box">
                  <select name="sorting" id="sorting" defaultValue="1" style={{width:"120px",fontSize:"14px"}} onChange={e=>onChangeSortHandler(e)}>
                    <option value={1}>최근 등록순</option>
                    <option value={2}>인기순</option>
                  </select>
                </li>
              </div>
            </div>
          </div>
        </div>  
        <div className="main_dunggi list_type02">
          {/* 파트너스 목록 */}
          {varShowList?.length===0?
            <div style={{textAlign:"center",height:"30px",marginTop:"10px"}}>검색된 파트너게시물이 없습니다.</div>
          :
            <>
              {varShowList.map((product,i)=>(
                <div className="list_con" key={'product_'+i} ref={e=>(scrollRef.current[i]=e)}>
                  <PartnerProduct inum={i} product={product} varPartnerList={varPartnerList} setPartnerList={setPartnerList}/>
                </div>
              ))}
              {/* 더보기 버튼은 숨기고 무한 스크롤로 대체 */}
              {isLoading && (
                <div className="loading-container">
                  <div className="loading-spinner">
                  </div>
                </div>
              )}
              {/* 스크롤 감지를 위한 참조 요소 */}
              <div ref={loadMoreRef} style={{ height: '1px' }}></div>
            </>
          }        
        </div>
      </div>
    </section>
  )
}
export default SearchedPartnerResult;