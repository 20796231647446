/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React,{ useState,useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../configs/Configs';

function Menus({ varMenuType, varMenuItem }) {
  const navigate = useNavigate();

  //로그인유무
  const adminLoginState = Number(localStorage.getItem('adminLoginState'));
  //console.log(adminLoginState,varMenuType,varMenuItem);
  //로그인 회원 상태 : 1=운영자/2=부운영자/3=지역관리자
  const adminMode = Number(localStorage.getItem('adminMode'));
  let menuAvailablesString = localStorage.getItem('menuAvailables');
  if(menuAvailablesString === null || menuAvailablesString === "" ) {
    menuAvailablesString = "0|0|0|0|0|0";
  }
  const menuAvailablesArray = menuAvailablesString.split('|');

  //새로운 신고 건수
  const [varNewReports,setNewReports] = useState();
  //기타 서비스 신청중 상태
  const [newServiceStates, setNewServiceStates] = useState({
    register: 0,
    search: 0,
    manage: 0,
    partner: 0
  });

  const readServiceState = async() => {
    console.log("Calling readServiceState");
    await axios.post(`${CONFIG.SERVER_HOST}/admin_get_service_state`).then((res) => {
      const datas = res.data;
      console.log("Service state response:", datas);
      if(datas.retvalue === 1) {
        setNewServiceStates({
          register: datas.register || 0,
          search: datas.search || 0,
          manage: datas.manage || 0,
          partner: datas.partner || 0
        });
      }
    }).catch(error => {
      console.error("Error in readServiceState:", error);
    });
  }

  // 전역으로 readServiceState 함수를 사용할 수 있도록 window 객체에 할당
  window.updateServiceState = readServiceState;

  const readReportState = async() => {
    await axios.post(`${CONFIG.SERVER_HOST}/admin_get_report_state`).then((res) => {
      const datas = res.data;
      //console.log(datas);
      if(datas.retvalue === 1) {
        setNewReports(datas.tcount);
      }
    });
  }

  useEffect(() => {
    if(Number(localStorage.getItem('adminLoginState')) === 0) {
      navigate('/Admin/Login')
    }
    readReportState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varMenuItem, varMenuType]);

  useEffect(() => {
    if(varMenuType === "PRODUCTS") {
      readServiceState();
    }
  }, [varMenuItem, varMenuType]);

  
  if (varMenuType === "ENVIRONMENT" && Number(menuAvailablesArray[0]) === 1) {
    return (
      <div id="left-wraps">
        <div className="logo">
          <p className="h1">둥지관리시스템</p>
        </div>
        <div id="gnbs">
          <ul className="depth1">
            {/* 로그인한 경우에만 표시 */}
            {adminLoginState === 0 ? "" :
              <li className={varMenuItem === "AdminState" || varMenuItem === "SubAdminState" || varMenuItem === "AgencyAdminState"
                          || varMenuItem === "SubAdminInformation" || varMenuItem === "AgencyAdminInformation"? "selectedmenu" : ""}>
                <a>운영 계정 관리</a>
                <ul className="depth2">
                  {adminMode === 2 ? 
                      <li className="m03">
                      <Link to={"/Admin/Environment/SubAdminInformation"}>
                        <span className={varMenuItem === "SubAdminInformation" ? "selectedlimenu" : ""}>- 부운영자 관리</span>
                      </Link>
                    </li>
                  :adminMode === 3 ? 
                    <li className="m03">
                      <Link to={"/Admin/Environment/AgencyAdminInformation"}>
                        <span className={varMenuItem === "AgencyAdminInformation" ? "selectedlimenu" : ""}>- 지역관리자 관리</span>
                      </Link>
                    </li>
                  :
                    <>
                      <li className="m03">
                        <Link to={"/Admin/Environment/AdminState"}>
                          <span className={varMenuItem === "AdminState" ? "selectedlimenu" : ""}>- 운영자 관리</span>
                        </Link>
                      </li>
                      <li className="m03">
                        <Link to={"/Admin/Environment/SubAdminState"}>
                          <span className={varMenuItem === "SubAdminState" ? "selectedlimenu" : ""}>- 부운영자 관리</span>
                        </Link>
                      </li>
                      <li className="m03">
                        <Link to={"/Admin/Environment/AgencyAdminState"}>
                          <span className={varMenuItem === "AgencyAdminState" ? "selectedlimenu" : ""}>- 지역 관리자 관리</span>
                        </Link>
                      </li>
                    </>
                  }
                </ul>
              </li>
            }
            {/* 운영정보관리 */}
            <li className={varMenuItem === "CompanyAccount" || varMenuItem === "CompanyInfo" ? "selectedmenu" : ""}>
              <a>운영 정보 관리</a>
              <ul className="depth2">
                <li className="m03">
                  <Link to={"/Admin/Company/CompanyAccount"}>
                    <span className={varMenuItem === "CompanyAccount" ? "selectedlimenu" : ""}>- 입금 계좌 관리</span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to={"/Admin/Company/CompanyInfo"}>
                    <span className={varMenuItem === "CompanyInfo" ? "selectedlimenu" : ""}>- 업체 정보 관리</span>
                  </Link>
                </li>
              </ul>
            </li>
            {/* 카테고리관리 */}
            {/* <li className={varMenuItem === "DoongjiVariables" || varMenuItem === "JangterVariables" || varMenuItem === "SubwayVariables"? "selectedmenu" : ""}>
              <a>카테고리 관리</a>
              <ul className="depth2">
                <li className="m03">
                  <Link to={"/Admin/Variables/DoongjiVariables"}>
                    <span className={varMenuItem === "DoongjiVariables" ? "selectedlimenu" : ""}>- 둥지 카테고리</span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to={"/Admin/Variables/JangterVariables"}>
                    <span className={varMenuItem === "JangterVariables" ? "selectedlimenu" : ""}>- 장터 카테고리</span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to={"/Admin/Variables/SubwayVariables"}>
                    <span className={varMenuItem === "SubwayVariables" ? "selectedlimenu" : ""}>- 지하철 카테고리</span>
                  </Link>
                </li>
              </ul>
            </li> */}
            {/* 약관관리 */}
            <li className={varMenuItem === "ServiceTerm" || varMenuItem === "Privacy" || varMenuItem === "Marketing" ? "selectedmenu" : ""}>
              <a>약관 관리</a>
              <ul className="depth2">
                <li className="m03">
                  <Link to={"/Admin/Terms/AdminTerms/1"}>
                    <span className={varMenuItem === "ServiceTerm" ? "selectedlimenu" : ""}>- 이용약관</span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to={"/Admin/Terms/AdminTerms/2"}>
                    <span className={varMenuItem === "Privacy" ? "selectedlimenu" : ""}>- 개인정보처리방침</span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to={"/Admin/Terms/AdminTerms/3"}>
                    <span className={varMenuItem === "Marketing" ? "selectedlimenu" : "" }>- 마케팅정보수신동의</span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to={"/Admin/Terms/AdminTerms/4"}>
                    <span className={varMenuItem === "ProductManage" ? "selectedlimenu" : "" }>- 매물관리정책동의</span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to={"/Admin/Terms/AdminTerms/5"}>
                    <span className={varMenuItem === "SupplyInformation" ? "selectedlimenu" : "" }>- 제3자정보제공동의</span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  /* 회원관리 */
  } else if (varMenuType === "MEMBER" && Number(menuAvailablesArray[1]) === 1) {
    return (
      <div id="left-wraps">
        <div className="logo">
          <p className="h1">둥지관리시스템</p>
        </div>
        <div id="gnbs">
          <ul className="depth1">
            {adminLoginState === 0 ? "" :
            <>
              <li className={varMenuItem === "MemberListN" || varMenuItem === "MemberListC" || varMenuItem === "MemberListL" || varMenuItem === "MemberListR" || varMenuItem === "MemberListB"? "selectedmenu" : ""}>
                <a>회원 관리</a>
                <ul className="depth2">
                  <li className="m03">
                    <Link to={"/Admin/Member/MemberLists/1"}>
                      <span className={varMenuItem === "MemberListN" ? "selectedlimenu" : ""}>- 일반 회원 관리</span>
                    </Link>
                  </li>
                  <li className="m03">
                    <Link to={"/Admin/Member/MemberLists/2"}>
                      <span className={varMenuItem === "MemberListC" ? "selectedlimenu" : ""}>- 부동산파트너 관리</span>
                    </Link>
                  </li>
                  <li className="m03">
                    <Link to={"/Admin/Member/MemberLists/3"}>
                      <span className={varMenuItem === "MemberListL" ? "selectedlimenu" : ""}>- 사업자파트너 관리</span>
                    </Link>
                  </li>
                  <li className="m03">
                    <Link to={"/Admin/Member/MemberLists/0"}>
                      <span className={varMenuItem === "MemberListR" ? "selectedlimenu" : ""}>- 탈퇴 회원 관리</span>
                    </Link>
                  </li>
                  <li className="m03">
                    <Link to={"/Admin/Member/MemberLists/-1"}>
                      <span className={varMenuItem === "MemberListB" ? "selectedlimenu" : ""}>- 차단 회원 관리</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={varMenuItem === "MemberApproval" ? "selectedmenu" : ""}>
                <a>파트너 승인 관리</a>
                <ul className="depth2">
                  <li className="m03">
                    <Link to={"/Admin/Member/MemberApproval"}>
                      <span className={varMenuItem === "MemberApproval" ? "selectedlimenu" : ""}>- 파트너 회원 승인</span>
                    </Link>
                  </li>
                </ul>
              </li>
              </>
            }          
          </ul>
        </div>
      </div>
    );
  /* 둥지/장터 관리 */
  } else if (varMenuType === "PRODUCTS" && Number(menuAvailablesArray[2]) === 1) {
    return (
      <div id="left-wraps">
        <div className="logo">
          <p className="h1">둥지관리시스템</p>
        </div>
        <div id="gnbs">
          <ul className="depth1">
            <li className={varMenuItem === "DoongjiList" || varMenuItem === "JangterList" || varMenuItem === "PartnerList" || varMenuItem === "ReportList" ? "selectedmenu" : ""}>
              <a>이용 관리</a>
              <ul className="depth2">
                <li className="m03">
                  <Link to={"/Admin/ProductList/DoongjiList"}>
                    <span className={varMenuItem === "DoongjiList" ? "selectedlimenu" : ""}>- 둥지 매물 관리</span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to={"/Admin/ProductList/JangterList"}>
                    <span className={varMenuItem === "JangterList" ? "selectedlimenu" : ""}>- 장터 게시물 관리</span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to={"/Admin/ProductList/PartnerList"}>
                    <span className={varMenuItem === "PartnerList" ? "selectedlimenu" : ""}>- 사업자 게시물 관리</span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to={"/Admin/ProductList/ReportList"}>
                    <span className={varMenuItem === "ReportList" ? "selectedlimenu" : ""}>- 신고물 관리</span>
                    {varNewReports > 0?<span style={{color:"red",fontSize:"14px",marginLeft:"10px"}}>{varNewReports}</span>:null}
                  </Link>
                </li>
              </ul>
            </li>  
            <li className={varMenuItem === "DoongjiPADList" || varMenuItem === "JangterPADList" ? "selectedmenu" : ""}>
              <a>프리미엄 광고 관리</a>
              <ul className="depth2">
                <li className="m03">
                  <Link to={"/Admin/ProductAdvertise/PremiumList/1"}>
                    <span className={varMenuItem === "DoongjiPADList" ? "selectedlimenu" : ""}>- 둥지 프리미엄 광고 관리</span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to={"/Admin/ProductAdvertise/PremiumList/2"}>
                    <span className={varMenuItem === "JangterPADList" ? "selectedlimenu" : ""}>- 장터 프리미엄 광고 관리</span>
                  </Link>
                </li>
              </ul>
            </li>    
            <li className={varMenuItem === "DoongjiRegister" || varMenuItem === "DoongjiSearch" || varMenuItem === "DoongjiManage" || varMenuItem === "DoongjiPartner" ? "selectedmenu" : ""}>
              <a>기타 서비스 관리</a>
              <ul className="depth2">
                <li className="m03">
                  <Link to={"/Admin/ProductService/DoongjiRegister"}>
                    <span className={varMenuItem === "DoongjiRegister" ? "selectedlimenu" : ""}>
                      - 내 둥지 내놓기
                      {newServiceStates.register > 0 ? <span style={{color:"red",fontSize:"14px",marginLeft:"10px"}}>new</span> : null}
                    </span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to={"/Admin/ProductService/DoongjiSearch"}>
                    <span className={varMenuItem === "DoongjiSearch" ? "selectedlimenu" : ""}>
                      - 내 둥지 찾아주세요
                      {newServiceStates.search > 0 ? <span style={{color:"red",fontSize:"14px",marginLeft:"10px"}}>new</span> : null}
                    </span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to={"/Admin/ProductService/DoongjiManage"}>
                    <span className={varMenuItem === "DoongjiManage" ? "selectedlimenu" : ""}>
                      - 건물 월관리 의뢰
                      {newServiceStates.manage > 0 ? <span style={{color:"red",fontSize:"14px",marginLeft:"10px"}}>new</span> : null}
                    </span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to={"/Admin/ProductService/DoongjiPartner"}>
                    <span className={varMenuItem === "DoongjiPartner" ? "selectedlimenu" : ""}>
                      - 공인중개사 파트너 모집
                      {newServiceStates.partner > 0 ? <span style={{color:"red",fontSize:"14px",marginLeft:"10px"}}>new</span> : null}
                    </span>
                  </Link>
                </li>
              </ul>
            </li>       
          </ul>
        </div>
      </div>
    );
  /* 요금/이용권 관리 */
  } else if (varMenuType === "PAYMENTS" && Number(menuAvailablesArray[3]) === 1) {
    return (
      <div id="left-wraps">
        <div className="logo">
          <p className="h1">둥지관리시스템</p>
        </div>
        <div id="gnbs">
          <ul className="depth1">
            <li className={varMenuItem === "DoongjiRatePlan" || varMenuItem === "JangterRatePlan" ? "selectedmenu" : ""}>
              <a>요금제 관리</a>
              <ul className="depth2">
                <li className="m03">
                  <Link to={"/Admin/RatePlan/DoongjiRatePlan"}>
                    <span className={varMenuItem === "DoongjiRatePlan" ? "selectedlimenu" : ""}>- 둥지 파트너 요금제</span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to={"/Admin/RatePlan/JangterRatePlan"}>
                    <span className={varMenuItem === "JangterRatePlan" ? "selectedlimenu" : ""}>- 장터 파트너 요금제</span>
                  </Link>
                </li>
              </ul>
            </li>  
            <li className={varMenuItem === "PaymentVoucherList" || varMenuItem === "PaymentPremiumList" ? "selectedmenu" : ""}>
              <a>이용권 관리</a>
              <ul className="depth2">
                <li className="m03">
                  <Link to={"/Admin/Payment/PaymentVoucherList"}>
                    <span className={varMenuItem === "PaymentVoucherList" ? "selectedlimenu" : ""}>- 이용권 결제 관리</span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to={"/Admin/Payment/PaymentPremiumList"}>
                    <span className={varMenuItem === "PaymentPremiumList" ? "selectedlimenu" : ""}>- 프리미엄 결제 관리</span>
                  </Link>
                </li>
              </ul>
            </li>  
          </ul>
        </div>
      </div>
    );
  /* 게시판 관리 */
  } else if (varMenuType === "BOARDS" && Number(menuAvailablesArray[4]) === 1) {
    return (
      <div id="left-wraps">
        <div className="logo">
          <p className="h1">둥지관리시스템</p>
        </div>
        <div id="gnbs">
          <ul className="depth1">
            <li className={varMenuItem === "BoardNotice" || varMenuItem === "BoardFAQ" || varMenuItem === "BoardOneToOne" ? "selectedmenu" : ""}>
              <a>앱 게시판 관리</a>
              <ul className="depth2">
                <li className="m03">
                  <Link to={"/Admin/Board/BoardNotice"}>
                    <span className={varMenuItem === "BoardNotice" ? "selectedlimenu" : ""}>- 공지사항</span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to={"/Admin/Board/BoardFAQ"}>
                    <span className={varMenuItem === "BoardFAQ" ? "selectedlimenu" : ""}>- FAQ</span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to={"/Admin/Board/BoardOneToOne"}>
                    <span className={varMenuItem === "BoardOneToOne" ? "selectedlimenu" : ""}>- 1:1 문의/답변</span>
                  </Link>
                </li>
              </ul>
            </li>  
          </ul>
        </div>
      </div>
    );
  /* 관리자 게시판 관리 */
  } else if (varMenuType === "ADMINBOARDS" && Number(menuAvailablesArray[5]) === 1) {
    return (
      <div id="left-wraps">
        <div className="logo">
          <p className="h1">둥지관리시스템</p>
        </div>
        <div id="gnbs">
          <ul className="depth1">
            <li className={varMenuItem === "AdminBoardNotice"|| varMenuItem === "AdminQuestion" || varMenuItem === "AdminNews"  || varMenuItem === "AdminGuestBoard" || varMenuItem === "AdminSaleDoongji" || varMenuItem === "AdminFindDoongji" ? "selectedmenu" : ""}>
              <a>관리자 게시판 관리</a>
              <ul className="depth2">
                <li className="m03">
                  <Link to={"/Admin/AdminBoard/1"}>
                    <span className={varMenuItem === "AdminBoardNotice" ? "selectedlimenu" : ""}>- 공지사항</span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to={"/Admin/AdminQuestion"}>
                    <span className={varMenuItem === "AdminQuestion" ? "selectedlimenu" : ""}>- 1:1 문의</span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to={"/Admin/AdminBoard/2"}>
                    <span className={varMenuItem === "AdminNews" ? "selectedlimenu" : ""}>- 뉴스/개발</span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to={"/Admin/AdminBoard/3"}>
                    <span className={varMenuItem === "AdminGuestBoard" ? "selectedlimenu" : ""}>- 지역관리자 커뮤니티</span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to={"/Admin/AdminBoard/4"}>
                    <span className={varMenuItem === "AdminSaleDoongji" ? "selectedlimenu" : ""}>- 급매물/공동중개</span>
                  </Link>
                </li>
                <li className="m03">
                  <Link to={"/Admin/AdminBoard/5"}>
                    <span className={varMenuItem === "AdminFindDoongji" ? "selectedlimenu" : ""}>- 매물찾습니다</span>
                  </Link>
                </li>
              </ul>
            </li>  
          </ul>
        </div>
      </div>
    );
  } else if (varMenuType === "") {
    return (
      <div id="left-wraps">
        <div className="logo">
          <p className="h1">둥지관리시스템</p>
        </div>
        <div id="gnbs" style={{height:"calc(100vh - 101px)",overflow:"hidden"}}></div>
      </div>
    );
  }
}

export default Menus;
